import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import './DUM.css'
import Cookies from 'js-cookie'
import axios from 'axios';
import { message } from 'antd';

export default function DeleteUserModal({ closeMenu, userId }) {
  const userII = JSON.parse(Cookies.get('userInfo'));
  const token = userII.user_id;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeMenu();
};
  
const handleDeleteUser = async () => {
  const data = {
    userId: userId,
    userAdminId: token  // Ensure 'token' is defined and available in your scope
  };

  try {
    const response = await axios.post('https://api.goldenowlsagency.com/delete-user', data);

    if (response.data.message) {
      message.success('User deleted successfully');
      setTimeout(() => {
        window.location.reload(); // Reload the page after 3 seconds
      }, 1000);
    } else if (response.data.error) {
      message.error(response.data.error);
    } else {
      message.error('Unknown error occurred');
    }

  } catch (err) {
    console.error('Error:', err.message);
    message.error('Failed to delete user. Please try again later.');
  }
};

  return (
    <div>
      <a type="button" onClick={handleOpen}>
        Delete
      </a>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 500 }}>
            <h2 id="unstyled-modal-title" className="modal-title" style={{textAlign: 'center'}}>
                Warning this change its not reversible 
            </h2>
            <div className='DeleteUserButtons'>
                <Stack direction="row" spacing={2} >
                    <Button onClick={handleDeleteUser} variant="outlined" sx={{color:'rgb(249,180,30)', borderColor:'rgb(249,180,30)',
                                                    '&:hover':{borderColor: 'red', color:'red'}
                    }} startIcon={<DeleteIcon />} >
                        Delete
                    </Button>
                    <Button variant="contained" onClick={handleClose} endIcon={<SendIcon />} sx={{
                        backgroundColor:'rgb(249,180,30)', 
                        '&:hover':{ backgroundColor:'rgb(249,180,30)'}}}>
                        Cancel
                    </Button>
                </Stack>
            </div>
          
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const DeleteButton = {

}


const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: rgb(12,12,12);
    border-radius: 8px;
    border: 1px solid rgb(249,180,30);
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: white;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
      color: red
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

