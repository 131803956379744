import React, { useState, useEffect} from 'react';
import Slider from './slider';
import Icon_menu from './icon_menu'
import './css/Workspace.css'
import { useSwipeable } from 'react-swipeable';
import Cookies from 'js-cookie'
import LastFileModal from '../workspaceComponen/LastFileModal';
import FullCalendar from '@fullcalendar/react';
import axios from 'axios';
import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Mosaic } from 'react-loading-indicators';
import { Tune } from '@mui/icons-material';


// eslint-disable-next-line no-undef


const Workspace = () => {
  let cont = 0;
  const userII = JSON.parse(Cookies.get('userInfo'));
  const token = userII.user_id;
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isGoogleWorkspaceOpen, setIsGoogleWorkspaceOpen] = useState(false);
  const [isPhone, setPhone] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(true);

  const colorMapping = {
    1: '#ff5e00',  // Naranja
    2: '#16c79a',  // Verde Claro
    3: '#9c27b0',  // Purpura
    4: '#f54ea4',  // Rosado
    5: '#0062cc',  // Azul
    6: '#ffb60a',  // Amarillo
    7: '#4e9f3d',  // Cian
    8: '#0e0e0e',  // Gris
    9: '#000000',  // Negro
    10: '#02a502', // Verde
    11: '#9e1b32'  // Rojo
  };

  useEffect(() => {
    fetch('https://api.goldenowlsagency.com/images')
      .then(response => response.json())
      .then(data => setImageList(data.images))
      .catch(err => console.error("Error cargando las imágenes", err));
  }, []);

  const fetchCalendarEvents = async () => {
    try {
        const response = await axios.post("https://api.goldenowlsagency.com/get-calendar", {
            userId: token, // Ejemplo de payload
        });
        
        // Transformar los eventos al formato de FullCalendar
        const transformedEvents = response.data.map(event => ({
            title: event.title,
            start: event.start,
            end: event.end,
            description: event.description,
            color: colorMapping[event.colorId] || '#2196f3',
            calendarId: event.calendarId,
            eventId:event.eventId,
        }));

        setEvents(transformedEvents);
        cont= cont + 1;
        //await console.log(events)
    } catch (error) {
        console.error("Error fetching calendar events:", error);
    }
};

  const isOrNotPhone = () => {
    if (window.innerWidth >= 768){
      setPhone(false)
    } else{
      setPhone(true)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, 10000); // 10 segundos
    return () => clearInterval(interval);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length),
    onSwipedRight: () =>
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length
      ),
  });

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    isOrNotPhone()
  }, [isPhone]);

  const [fileData, setFileData] = useState(null);
    const [error, setError] = useState(null);

   
        // Definir la función para obtener datos del archivo
        const fetchLastFile = async () => {
            try {
                const response = await fetch('https://api.goldenowlsagency.com/last-file', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: token })
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const data = await response.json();
                setFileData(data.data); // Guardar datos del archivo en el estado
                cont= cont + 1
            } catch (error) {
                console.error('Error fetching last file:', error);
                setError(error.message); // Guardar error en el estado
            }
        };

  const toggleGoogleWorkspace = () => {
    setIsGoogleWorkspaceOpen(!isGoogleWorkspaceOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
          if (userII.if_login === 1) {
            await fetchCalendarEvents();
          }
            await fetchLastFile();
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, []);

  if (loading) return <div className='loading'><Mosaic color="rgb(249,180,30)" size="large" text="Loading..." textColor="" /></div>;
  return (
    <div className="workplace-container">
      <h1 className='main-title'>WorkSpace</h1> {/* Main title*/}

      {/* start of the promotion*/}
      {!isPhone && ( <div className='sidebar'>
        <div className='sidebar-content2'>
        <div className="slider-container2" {...handlers}>
      <div
        className="slider2"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {imageList.map((image, index) => (
          <div className="slide2" key={index}>
            <img src={image} alt={`Slide2 ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="navigation2">
        {imageList.map((_, index) => (
          <button
            key={index}
            className={`nav-button2 ${index === currentIndex ? 'active2' : ''}`}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
        </div>
      </div>)}

      {/* start of the main content*/}
      
      <div className='main-contect'>
      {userII.if_login == 1 ? (
        <div className='calendar'>
        <FullCalendar 
          plugins={[timeGridPlugin]}
          initialView='timeGridDay'
          events={events}
          dayMaxEventRows={3}
        />
        </div>
      ):(
      <div className='calendar'>
        <h3><a href='/UserInfo'>Please login with Google here</a></h3>
      </div>)}
        

        <div className='recent-document'>
        <LastFileModal/>
        </div>
        {/*Chat here*/}
        <div className='list-chats'>
          <h3> Chats Here</h3> {/* this is temporal */}
        </div>

        <div className='idk'>
          <h3>I Don't Know</h3> {/* this is temporal */}
        </div>

      </div>
      <Slider></Slider>
      <Icon_menu/>
    </div>
  );
};

export default Workspace;