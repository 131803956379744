import React from "react";
import '../css/user-list.css'
import Slider from "../slider";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import User_Options from "./user-options";
import Icon_menu from "../icon_menu";
import { Autocomplete, TextField } from "@mui/material";
import Cookies from 'js-cookie';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SeeUserInformation from "./SeeUserInfomatio";

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

// eslint-disable-next-line no-undef
const images = importAll(require.context('../image', false, /\.(png|jpe?g|svg)$/));

const User_List = () =>{
    const [filteredChanges, setFilteredChanges] = useState([]); // Almacena los archivos del folder
    const [userList, setUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [Changes, setChanges] = useState([]);
    const [sortOrder, setSortOrder] = useState("recent");
    const [imgUser2, SetImgUser] = useState('');
    const [selectedUserId, setSelectedUserId] = React.useState(null); // To store the clicked user's ID
    const [modalOpen, setModalOpen] = React.useState(false);          // To control modal visibility
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [isPhone, setIsPhone] = useState(false);





    const filesPerPage = 7;
    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = filteredChanges.slice(indexOfFirstFile, indexOfLastFile);

   

    const handleOpenModal = (userId) => {
      setSelectedUserId(userId);  // Store the selected user ID in state
      setModalOpen(true);   
    };
    

    function hanldePhone(){
      if (window.innerWidth <= 768){
          setIsPhone(true)
      }
    }
  
    useEffect(()=>{
      hanldePhone();
    },[])

    const handleCloseModal = () => {
      setModalOpen(false);       // Close the modal
    };

    const handleSortOptionClick = (order) => {
      setSortOrder(order);
      setShowSortOptions(false);
    };

    useEffect(() => {
        const fetchUser = async () => {
          //const token = localStorage.getItem('token');
          try {
            const response = await axios.get(`https://api.goldenowlsagency.com/GetAllUser`);
            if (Array.isArray(response.data) && response.data.length > 1) {
              setFilteredChanges(response.data); // users data
              setUserList(response.data)
              setChanges(response.data);
              //console.log(folders)
            } else {
              console.error('Error fetching folders:', response.data);
            }
          } catch (error) {
            console.error('Error fetching folders:', error);
          }
        };
        fetchUser();
       
      }, []);

      useEffect(() => {
        let results = Changes.filter(change =>
          change.user_name.toLowerCase().includes(filter.toLowerCase()) ||
          change.role_name.toLowerCase().includes(filter.toLowerCase()) 
        );
    
        if (sortOrder === "alphabeticalAsc") {
          results = results.sort((a, b) => a.user_name.localeCompare(b.user_name));
        } else if (sortOrder === "alphabeticalDesc") {
          results = results.sort((a, b) => b.user_name.localeCompare(a.user_name));
        }  
        
    
        setFilteredChanges(results);
        setCurrentPage(1); // reset to first page when filter changes
      }, [filter, Changes, sortOrder]);

    const totalPages = Math.ceil(filteredChanges.length / filesPerPage);
  

    return(
        <div className="main-user-content">
            <div className="search-bar-2">

          <input
            src={images['search-normal.png']}
            type="text"
            placeholder="Search..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
            <h1 className="main-user-title">Users</h1>

            <div className="main2-user-content">
            <div className="div-button" >
              <a href="/NewEmployee">
                <button className="filled-button">Add User</button>
              </a>
            </div>

                <div className="sort-by" style={{top: '-5vh'}}>
                <Box 
                  sx={{ 
                    minWidth: 200, 
                    backgroundColor: 'rgb(12,12,12)',  
                    color: 'rgb(249,180,30)',          
                    borderRadius: '50px !important',
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel 
                      sx={{ 
                        color: 'rgb(249,180,30)',
                        "&.Mui-focused": {
                          color: 'rgb(249,180,30)', 
                        },  
                      }} 
                      id="demo-simple-select-label"
                    >
                      Sort
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sort"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: 'rgb(12,12,12)',
                            
                          },
                        },
                      }}
                      sx={{
                        backgroundColor: 'rgb(12,12,12)',  // Color de fondo del menú desplegable
                        color: 'rgb(249,180,30)' ,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(249,180,30)", // Color del borde cuando está seleccionado
                            },       // Color del texto en el menú
                      }}
                    >
                      <MenuItem 
                        onClick={() => { handleSortOptionClick('alphabeticalAsc') }} 
                        value={10}
                        sx={{ color: 'rgb(249,180,30)',
                          "&.Mui-selected": {
                          backgroundColor: 'rgb(249,180,30)', // Color del item seleccionado
                          color: 'black' // Color de texto del item seleccionado
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: 'rgb(249,180,30)', // Color del item seleccionado cuando pasas el mouse
                        },
                                          }} // Color del texto de los elementos del menú
                      >
                        Newest
                      </MenuItem>
                      <MenuItem 
                        onClick={() => { handleSortOptionClick('alphabeticalDesc') }} 
                        value={20}
                        sx={{ color: 'rgb(249,180,30)',
                          "&.Mui-selected": {
                            backgroundColor: 'rgb(249,180,30)', // Color del item seleccionado
                            color: 'black' // Color de texto del item seleccionado
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: 'rgb(249,180,30)', // Color del item seleccionado cuando pasas el mouse
                          },
                         }} // Color del texto de los elementos del menú
                      >
                        Oldest
                      </MenuItem>
                      <MenuItem 
                        value={30}
                        sx={{ color: 'rgb(249,180,30)',
                          "&.Mui-selected": {
                            backgroundColor: 'rgb(249,180,30)', // Color del item seleccionado
                            color: 'black' // Color de texto del item seleccionado
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: 'rgb(249,180,30)', // Color del item seleccionado cuando pasas el mouse
                          },
                         }} // Color del texto de los elementos del menú
                      >
                        Thirty
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

        </div>
                <div className="black-user-content">
                <div className="content-table">
                    <table className="activity-table">
                        <thead>
                            <tr>
                            <th className="th1">USER NAME</th>
                            <th>USER ROLE</th>
                           
                            <th className="th2">MORE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFiles.map((file, index) => (
                            <tr 
                            

                            key={index}>
                                <td onClick={() => handleOpenModal(file.user_id)} 
                            style={{cursor: 'pointer'}}>
                                <img 
                                src={file.user_img == null ? images['usuario.png'] : file.user_img} // Correct ternary operator // Función que devuelve la URL de la imagen
                                alt={file.type} 
                                style={{ display: 'inline', width: '30px', height: '30px', marginRight: '8px' , borderRadius: '50%'}} // Estilo para la imagen
                                />
                                {file.user_name}</td>
                                <td onClick={() => handleOpenModal(file.user_id)} 
                            style={{cursor: 'pointer'}}>{file.role_name}</td>
                                <td><User_Options UserId={ file.user_id} /></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>

                
                </div>

                <div className="nav-move-pages2">
        <button className="next" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          <div className="next-text">Anterior</div>
        </button>
        <div className="page-numbers">
          {Array.from({ length: totalPages }, (_, index) => {
            const pageNumber = index + 1;
            if (
              pageNumber === 1 ||
              pageNumber === totalPages ||
              pageNumber === currentPage ||
              pageNumber === currentPage - 1 ||
              pageNumber === currentPage + 1
            ) {
              return (
                <span
                  key={index}
                  className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </span>
              );
            } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
              return <span key={index}>...</span>;
            }
            return null;
          })}
        </div>
        <button className="next" onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastFile >= filteredChanges.length}>
          <div className="next-text">Siguiente</div>
        </button>
      </div>

                </div>
            </div>
            <Slider></Slider>
            <Icon_menu/>
            <SeeUserInformation userId={selectedUserId} openVar={modalOpen} onClose={handleCloseModal}/>

        </div>
    )
}

export default User_List;