import React from 'react';

const datos = () => {
  return (
    <div>
      <h1>Entraste como usuario</h1>
    </div>
  );
};

export default datos;
