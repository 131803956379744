import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import Datos from './pages/datos'; 
import Admin from './pages/admin'; 
import UserInfo from './pages/UserInfo';
import Workspace from './pages/Workspace';
import DownloadFile from './pages/DownloadFile';
import ForgotPassword from './pages/forgot-password';
import NewEmployee from './pages/MacBookAir1';
import ResetConfirm from './pages/ResetConfirm';
import RecenChan from './pages/recentChange';
import FolderAccess from './pages/FolderAccess';
import Files from './pages/Files';
import Email from './pages/Email';
//import AdminSettings from './pages/test2.tsx';
import Settings from './pages/Settings';
import Thing from './pages/testf';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminSettings from './pages/admin-settings'
import User_List from './pages/users/users-list';
import Logs from './Logs';
import Calanedar from './pages/Calendar';
import MessagesC from './pages/MessageC';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/datos" element={<Datos />} /> 
        <Route path="/UserInfo" element={<UserInfo />} />
        <Route path="/test" element={<AdminSettings />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/DownloadFile" element={<DownloadFile />} />
        <Route path="/NewEmployee" element={<NewEmployee />} />
        <Route path="/ResetConfirm" element={<ResetConfirm />} />
        <Route path="/recentChange" element={<RecenChan />} />
        <Route path="/FolderAccess" element={<FolderAccess />} />
        <Route path='/WorkSpace' element={<Workspace/>}/>
        <Route path='/Files' element={<Files/>}/>
        <Route path='/Email' element={<Email/>}/>
        <Route path='/Settings' element={<Settings/>}/>
        <Route path='/thing' element={<Thing/>}/>
        <Route path="*" element={<Navigate to="/" />} /> 
        <Route path='/ListUser' element={<User_List/>}/>
        <Route path='/Logs' element={<Logs/>}/>
        <Route path='/Calendar' element={<Calanedar/>}/>
        <Route path='/Messages' element={<MessagesC/>}/>
      </Routes>
    </BrowserRouter>
  );
}
7
export default App; // Ensure App is exported as default
