import React, { useEffect, useState } from 'react';
import './css/FolderAccess.css';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
};

const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const FolderAccess = ({ onClose }) => {
    const [folderStatus, setFolderStatus] = useState(null);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        const allUsersCheck = document.getElementById('all-users-check');
        const onlySelectCheck = document.getElementById('only-select-check');

        function handleCheckClick(event) {
            if (event.target.classList.contains('active')) {
                return; // No hacer nada si ya está activo
            }

            allUsersCheck.classList.remove('active');
            onlySelectCheck.classList.remove('active');
            event.target.classList.add('active');
        }

        allUsersCheck.addEventListener('click', handleCheckClick);
        onlySelectCheck.addEventListener('click', handleCheckClick);

        return () => {
            allUsersCheck.removeEventListener('click', handleCheckClick);
            onlySelectCheck.removeEventListener('click', handleCheckClick);
        };
    }, []); // El array vacío [] asegura que este efecto se ejecute solo una vez después del primer renderizado

    const closeStatusModal = () => {
        setFolderStatus(null);
        if (typeof onClose === 'function') {
            onClose(); // Ensure onClose is a function before calling it
        }
    };

    useEffect(() => {
        fetch('https://goldenowlsapi-testing.up.railway.app/api/GetAllRoles')
            .then(response => response.json())
            .then(data => {
                setRoles(data[0]);
            })
            .catch(error => console.error('Error fetching roles:', error));
    }, []);

    const handleSelectAll = () => {
        const allRoleIds = roles.map(role => role.role_id);
        setSelectedRoles(allRoleIds);
    };

    const handleDeselectAll = () => {
        setSelectedRoles([]);
    };

    const handleRoleCheckboxChange = (roleId) => {
        setSelectedRoles(prevSelectedRoles =>
            prevSelectedRoles.includes(roleId)
                ? prevSelectedRoles.filter(id => id !== roleId)
                : [...prevSelectedRoles, roleId]
        );
    };

    return (
        <div className="FolderAccess-Container">
            <div className="FolderAccess-SubContainer">
                <div className="FolderAccess-nav1">
                    <span className="FolderAccess-Title">
                        Manage who can download files
                    </span>
                    <div className="FolderAccess-icon-close">
                        <img
                            className="FolderAccess-img-close"
                            onClick={closeStatusModal}
                            src={images['close-circle.png']}
                            alt="Close"
                        />
                    </div>
                </div>
                <div className="FolderAccess-line"></div>
                <div className="FolderAccess-Choose">Choose who can access this file.</div>
                <div className="FolderAccess-nav2">
                    <div className="all-users-container">
                        <div className="check_folder" id="all-users-check"></div>
                        <span className="all-users-text">All users</span>
                    </div>
                    <div className="Onlyselect-container">
                        <div className="check_folder" id="only-select-check"></div>
                        <span className="onlyselect-text">Only select users</span>
                    </div>
                </div>
                <div className="FolderAccess-nav3">
                    <span className="FolderAccess-nav3-text">Selected users who have access to this file</span>
                    <span className="select-all" onClick={handleSelectAll}>Select all</span>
                    <span className="deselect-all" onClick={handleDeselectAll}>Deselect all</span>
                </div>
                <div className="FolderAccess-searchbox">
                    <div className="FolderAccess-searchbox-content">
                        <div className="FolderAccess-icon">
                            <img className="FolderAccess-img" src={images['search-normal.png']} alt="Search" />
                        </div>
                        <span className="FolderAccess-searchbox-text">Search by role...</span>
                    </div>
                </div>
                <table className="roles-table">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Grant Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map(role => (
                            <tr key={role.role_id}>
                                <td>{role.role_name}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        checked={selectedRoles.includes(role.role_id)}
                                        onChange={() => handleRoleCheckboxChange(role.role_id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="FolderAccess-nav-buttons" >
                    <div className="master-button-cancel-FolderAccess-Plus" onClick={closeStatusModal}>
                        <div className="master-button-cancel-FolderAccess">
                            <span className="button-cancel-FolderAccess-Text">Cancel</span>
                        </div>
                    </div>
                    <div className="master-button-add-FolderAccess-Plus">
                        <div className="master-button-add-FolderAccess">
                            <span className="button-add-FolderAccess-Text">Add user</span>
                        </div>
                    </div>
                </div>
            </div>
            {folderStatus && (
                <folderStatusModal message={folderStatus} onClose={closeStatusModal} />
            )}
        </div>
    );
};

export default FolderAccess;
