import React from 'react'
import './css/ResetConfirm.css';
import { useNavigate } from 'react-router-dom';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
};

const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const ResetConfirm = () => {
    const navigate = useNavigate();

    const handleSendRequest = () => {
        navigate('/ResetConfirm'); // Redirige a la página de confirmación
    };

    const handleCancel = () => {
        navigate('/login'); // Redirige a la página de inicio de sesión
    };

    return (
        <div className="forgot-password">
            <div className="background">
                <div className="city-background"></div>
                <div className="overlay"></div>
            </div>
            <div className="logos">
                <img className="logo-forgot-password" src={images['Logo.png']} alt="Logo" />
            </div>
            <div className="Resetconfirm-container">
                <div className="Resetconfirm-icon">
                    <img className="Resetconfirm-img" src={images['tick-circle.png']} alt="Lock Icon" />
                </div>
                <div className="Resetconfirm-Title">
                    Request Sent
                </div>
                <div className="Resetconfirm-text">
                    Your password reset request has been sent to the administrator.
                </div>
                <div className="Resetconfirm-button">
                    <span className="Resetconfirm-button-text" onClick={handleCancel}>
                        Back to Login
                    </span>
                </div>
                <p className="Resetconfirm-Subtext">
                    <span className="Resetconfirm-Subtext-plus">
                        If you do not receive a response, please check your spam folder or <span className="highlight">resend the request.</span>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default ResetConfirm;