import React, { useEffect, useState } from "react";
import Slider from "./slider";
import Icon_menu from "./icon_menu";
import NestedModal from './modal'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import './css/settings.css';
import { Button } from "@mui/material";
import GroupIcon from '@mui/icons-material/Group';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImageIcon, UserCog, History, LogIn, Download} from "lucide-react";
import { useNavigate } from 'react-router-dom';


const Settings = () => {
  const navigate = useNavigate(); // Hook para redirigir
  const [value, setValue] = React.useState(0);
  const [isPhone, setIsPhone] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleGoToLogs = () => {
    navigate('/Logs'); // Cambia '/files' por la ruta correcta si es diferente
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function hanldePhone(){
    if (window.innerWidth <= 768){
        setIsPhone(true)
    }
  }

  useEffect(()=>{
    hanldePhone();
  },[])

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{
            p: 3,
            backgroundColor: 'rgb(12, 12, 12)',
            marginTop: '1%',
            borderRadius: '10px',
            height: 'auto', // Permite que el panel se ajuste dinámicamente
            minHeight: '40vh' // Altura mínima para una mejor visualización
          }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));

  return (
    
    <div>
        {isPhone ? (
            <div>
            <div className="main-content-settings">
              <h1 style={{fontSize: '1.5rem', textAlign: 'center'}}>Settings</h1>
              <div className="settings-content" style={{padding: '1rem'}}>
                <div>
                  <Box sx={{ width: '100%', color: 'white', marginTop: '5%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        sx={{
                          backgroundColor: 'rgb(12,12,12)',
                          color: 'white',
                          borderRadius: '10px',
                          '& .MuiTabs-indicator': {
                            backgroundColor: 'rgba(249, 180, 30, 0.9) !important',
                          },
                          '& .Mui-selected': {
                              color: 'rgba(249, 180, 30, 0.9) !important', // Color del texto del Tab seleccionado
                            
                          },
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        value={value}
                        onChange={handleChange}
                        aria-label="tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab sx={{ color: 'white', minWidth: '120px' }} label="Usuarios" {...a11yProps(0)} />
                        <Tab sx={{ color: 'white', minWidth: '120px' }} label="Slider" {...a11yProps(1)} />
                        <Tab sx={{ color: 'white', minWidth: '120px' }} label="Roles" {...a11yProps(2)} />
                        <Tab sx={{ color: 'white', minWidth: '120px' }} label="Historial" {...a11yProps(3)} />
                      </Tabs>
                    </Box>
                      <CustomTabPanel value={value} index={0}>
                        <div className="user-content" style={{ textAlign: 'center' }}>
                          <h3 style={{ fontSize: '1.2rem' }}>Gestión de Usuarios</h3>
                          <p>Crear y ver usuarios del sistema</p>
      
                          <Button startIcon={<PersonAddAltIcon/>} sx={{
                              marginTop:'16vh', 
                              width:'100%', 
                              fontSize:'1.2rem', 
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                              }} variant="contained"><a href="/NewEmployee">Crear Usuario</a> </Button>
      
                          <Button startIcon={<GroupIcon/>} variant="contained" sx={{
                              marginTop:'1rem',
                              width:'100%',
                              fontSize:'1.2rem',
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                          }}>
                              <a href='/ListUser'>Ver Usuarios</a>
                          </Button>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <div className="slider-content" style={{ textAlign: 'center' }}>
                          <h3 style={{ fontSize: '1.2rem' }}>Gestión del Slider</h3>
                          <p>Administrar imagenes del Slider</p>
      
                          <Button startIcon={<DeleteIcon/>} sx={{
                              marginTop:'16vh', 
                              width:'100%', 
                              fontSize:'1.1rem', 
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                              }} variant="contained">Eliminar todas las imagenes</Button>
      
                          <Button   sx={{
                              marginTop:'1rem',
                              width:'100%',
                              fontSize:'1.2rem',
                              
                          }}>
                              <NestedModal/>
                          </Button>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={2}>
                        <div className="role-content" style={{ textAlign: 'center' }}>
                          <h3 style={{ fontSize: '1.2rem' }}>Gestión de Roles</h3>
                          <p>Crear y editar roles de usuario.</p>
      
                          <Button startIcon={<UserCog/>} sx={{
                              marginTop:'16vh', 
                              width:'100%', 
                              fontSize:'1.2rem', 
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                              }} variant="contained">Crear Role</Button>
      
                          <Button startIcon={<UserCog/>} variant="contained" sx={{
                              marginTop:'1rem',
                              width:'100%',
                              fontSize:'1.2rem',
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                          }}>
                              Editar Role
                          </Button>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={3}>
                        <div className="history-content" style={{ textAlign: 'center' }}>
                          <h3 style={{ fontSize: '1.2rem' }}>Historial</h3>
                          <p>Ver diferentes tipos de historiales.</p>
      
                          <Button onClick={handleGoToLogs} startIcon={<History/>} sx={{
                              marginTop:'16vh', 
                              width:'100%', 
                              fontSize:'1.2rem', 
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                              }} variant="contained">Historial de Cambios</Button>
      
                          <Button startIcon={<LogIn/>} variant="contained" sx={{
                              marginTop:'1rem',
                              width:'100%',
                              fontSize:'1.2rem',
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                          }}>
                              Historial de Logueo
                          </Button>
      
                          <Button startIcon={<Download/>} variant="contained" sx={{
                              marginTop:'1rem',
                              width:'100%',
                              fontSize:'1.2rem',
                              backgroundColor: '#F0B41E',
                              '&:hover': {
                                  backgroundColor: '#FFCB37',
                              },
                          }}>
                              Historial de descargas
                          </Button>
                        </div>
                      </CustomTabPanel>
                  </Box>
                </div>
              </div>
            </div>
            <Icon_menu />
          </div>
            ):(
                <div className="main-content-settings">
                <h1>Settings</h1>
                <div className="settings-content">
                  <div>
                    <Box sx={{ width: '85%', color: 'white', marginTop: '10%', marginLeft: '7%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          sx={{
                            backgroundColor: 'rgb(12,12,12)',
                            color: 'white',
                            borderRadius: '10px',
                            '& .MuiTabs-indicator': {
                              backgroundColor: 'rgb(249,180,30,0.9)!important',
                            },
                            '& .Mui-selected': {
                              color: 'rgba(249, 180, 30, 0.9)!important', // Color del texto del Tab seleccionado
                            },
                          }}
                          value={value}
                          onChange={handleChange}
                          aria-label="secondary tabs example"
                        >
                          <Tab sx={{ color: 'white', width: '240px', marginRight: '2%' }} label="Usuarios" {...a11yProps(0)} />
                          <Tab sx={{ color: 'white', width: '240px', marginRight: '2%' }} label="Slider" {...a11yProps(1)} />
                          <Tab sx={{ color: 'white', width: '240px', marginRight: '2%' }} label="Roles" {...a11yProps(2)} />
                          <Tab sx={{ color: 'white', width: '240px', marginRight: '2%' }} label="Historial" {...a11yProps(3)} />
                        </Tabs>
                      </Box>
                        <CustomTabPanel value={value} index={0}>
                          <div className="user-content">
                            <h3>Gestión de Usuarios</h3>
                            <p>Crear y ver usuarios del sistema</p>
        
                            <Button startIcon={<PersonAddAltIcon/>} sx={{
                                marginTop:'16vh', 
                                width:'auto', 
                                fontSize:'1.7vh', 
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                                }} variant="contained"><a href="/NewEmployee">Crear Usuario</a></Button>
        
                            <Button startIcon={<GroupIcon/>} variant="contained" sx={{
                                marginTop:'16vh',
                                width:'auto',
                                fontSize:'1.7vh',
                                marginLeft:'2%',
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                            }}>
                                <a href='/ListUser'>Ver Usuarios</a>
                            </Button>
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                        <div className="slider-content">
                            <h3>Gestión del Slider</h3>
                            <p>Administrar imagenes del Slider</p>
        
                            <Button startIcon={<DeleteIcon/>} sx={{
                                marginTop:'16vh', 
                                width:'auto', 
                                fontSize:'1.7vh', 
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                                }} variant="contained">Eliminar todas las imagenes</Button>

                            <Button className="casa"   sx={{
                                marginTop:'16vh',
                                width:'auto',
                                fontSize:'1.7vh',
                                marginLeft:'2%',
                               
                            }}>
                                <NestedModal/>
                            </Button>
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                          <div className="role-content">
                            <h3>Gestión de Roles</h3>
                            <p>Crear y editar roles de usuario.</p>
        
                            <Button startIcon={<UserCog/>} sx={{
                                marginTop:'16vh', 
                                width:'auto', 
                                fontSize:'1.7vh', 
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                                }} variant="contained">Crear Role</Button>
        
                            <Button className="casa" startIcon={<UserCog/>} variant="contained" sx={{
                                marginTop:'16vh',
                                width:'auto',
                                fontSize:'1.7vh',
                                marginLeft:'2%',
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                            }}>
                                Editar role
                            </Button>
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                        <div className="history-content">
                            <h3>Historial</h3>
                            <p>Ver diferentes tipos de historiales.</p>
        
                            <Button onClick={handleGoToLogs} startIcon={<History/>} sx={{
                                marginTop:'16vh', 
                                width:'auto', 
                                fontSize:'1.7vh', 
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                                }} variant="contained">Historial de Cambios</Button>
        
                            <Button startIcon={<LogIn/>} variant="contained" sx={{
                                marginTop:'16vh',
                                width:'auto',
                                fontSize:'1.7vh',
                                marginLeft:'2%',
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                            }}>
                                Historial de Logueo
                            </Button>
        
                            <Button startIcon={<Download/>} variant="contained" sx={{
                                marginTop:'16vh',
                                width:'auto',
                                fontSize:'1.7vh',
                                marginLeft:'2%',
                                backgroundColor: '#F0B41E',
                                '&:hover': {
                                    backgroundColor: '#FFCB37',
                                },
                            }}>
                                Historial de descargas
                            </Button>
                          </div>
                        </CustomTabPanel>
                    </Box>
                  </div>
                </div>
              </div>
            )}
      
      <Slider/>
      <Icon_menu />
    </div>
  );
};

export default Settings;
