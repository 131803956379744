import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css, positions } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Alert from '@mui/material/Alert';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UploadStatusModal from '../UploadStatusModal';  // Importa el componente del modal

export default function Edit_User_Modal({closeMenu ,User_Id}) {
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    closeMenu();
  };

  const [first_name, setFirstName] = useState(null);
  const [middle_name, setMiddleName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone_number, setPhone] = useState(null);
  const [sex, setSex] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [user_name, setUsername] = useState(null);
  const [user_password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState(null);
  const [identification_number, setIndeticationNumber] = useState(null);
  const [address, setAddress] = useState(null); // Nuevo estado para la dirección
  const [roles, setRoles] = useState([]);
  const [sexes, setSexes] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [modalMessage, setModalMessage] = useState('');  // Estado para el mensaje del modal
  const [showModal, setShowModal] = useState(false);     // Estado para controlar la visibilidad del modal

  useEffect(() => {
    const fetchNationalitiesAndSex = async () => {
      try {
        const response = await axios.get('https://api.goldenowlsagency.com/GetNationalitiesAndSex');
        if (Array.isArray(response.data) && response.data.length > 1) {
          const nationalitiesData = response.data[0][0]; // Datos de nacionalidades
          const sexesData = response.data[1][0]; // Datos de sexos
          setNationalities(nationalitiesData);
          setSexes(sexesData);
        } else {
          console.error('Error fetching nationalities and sex:', response.data);
        }
      } catch (error) {
        console.error('Error fetching nationalities and sex:', error);
      }
      console.log(User_Id)
    };

    fetchNationalitiesAndSex();

    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://api.goldenowlsagency.com/GetAllRoles');
        if (Array.isArray(response.data)) {
          setRoles(response.data);
        } else {
          console.error('Error fetching roles:', response.data);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const data = {
      first_name,
      middle_name,
      last_name,
      email,
      phone_number,
      sex,
      birthdate,
      nationality,
      user_name,
      user_password,
      role,
      identification_number,
      address, 
      user_id : User_Id,// Incluimos la dirección en los datos enviados al servidor
      admin_user: token // Usamos el token en lugar del valor estático
    };

    try {
      const response = await axios.post('https://api.goldenowlsagency.com/edit-user', data);
      console.log(response.data);
      setModalMessage('User edited');
      setShowModal(true);
      // Aquí podrías añadir lógica adicional como notificaciones o redireccionamientos
    } catch (error) {
      console.error('Error creating user:', error);
      setModalMessage('Error creating user');
      setShowModal(true);
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNameChange = (e, setName) => {
    const value = e.target.value;
    if (value.length <= 20 && /^[a-zA-Z]*$/.test(value)) {
      setName(value);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <a onClick={handleOpen2}>Edit</a>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        disablePortal
        disableRestoreFocus
        disableScrollLock
        disableEscapeKeyDown
        open={open2}
        onClose={handleClose2}
        slots={{ backdrop: StyledBackdrop }}
      >
        {/* ModalContent actualizado */}
        <ModalContent disableAutoFocus  autoFocus={false} sx={{ width: window.innerWidth<= 768 ? 400: 1400, maxHeight: '80vh', overflowY: 'auto', scrollbarWidth: '0' }}>
          <div className="mac-book-air-1" style={{position:'relative', marginRight:'100px'}} >
            <div className="header">
              <div className="users-header" style={{marginLeft: window.innerWidth<= 768 ? '-2vh':'30vh', marginTop:'1vh'}}>Edit the User Information</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-container" >
                <div className="personal-information-section" style={{marginLeft: window.innerWidth<= 768 ? '2vh': '30vh', marginTop: window.innerWidth<= 768 ? '-7vh':'-7vh'}}>
                  <div className="section-title">User information</div>
                  <div className="input-row">
                    <div className="input-group">
                      <label className="input-label">First Name*</label>
                      <input type="text" className="input-field"
                        value={first_name}
                        onChange={(e) => handleNameChange(e, setFirstName)}
                        placeholder="Name"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                    </div>
                    <div className="input-group">
                      <label className="input-label">Middle Name*</label>
                      <input type="text" className="input-field"
                        value={middle_name}
                        onChange={(e) => handleNameChange(e, setMiddleName)}
                        placeholder="Middle Name"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                    </div>
                    <div className="input-group">
                      <label className="input-label">Last Name*</label>
                      <input type="text" className="input-field"
                        value={last_name}
                        onChange={(e) => handleNameChange(e, setLastName)}
                        placeholder="Last Name"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label className="input-label">Email*</label>
                      <input type="email" className="input-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Type email address"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                    </div>
                    <div className="input-group">
                      <label className="input-label">Phone*</label>
                      <input type="tel" className="input-field"
                        value={phone_number}
                        onChange={handlePhoneChange}
                        placeholder="Type phone number"
                         />
                    </div>
                    <div className="input-group">
                      <label className="input-label">Gender</label>

                      <select className="input-field" type="select"
                        value={sex} onChange={(e) => setSex(e.target.value)} >
                        <option value="">Select Gender</option>
                        {sexes.map((s) => (
                          <option key={s.sex_id} value={s.sex_id}>{s.sex}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <label className="input-label">Date of birth</label>
                      <input type="date" className="input-field"
                        value={birthdate}
                        onChange={(e) => setBirthdate(e.target.value)}
                         />
                    </div>
                    <div className="input-group">
                      <label className="input-label">Nationality</label>
                      <select class="input-field" type="select"
                        value={nationality} onChange={(e) => setNationality(e.target.value)} >
                        <option value="">Select Nationality</option>
                        {nationalities.map((n) => (
                          <option key={n.nationality_id} value={n.nationality_id}>{n.nationality}</option>
                        ))}
                      </select>
                    </div>
                    <div className="input-group">
                      <label className="input-label">DNI or passport</label>
                      <input type="text" className="input-field"
                        value={identification_number}
                        onChange={(e) => setIndeticationNumber(e.target.value)}
                        placeholder="DNI"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                    </div>
                  </div>
                </div>
                <div className="terms-and-access-section" style={{marginLeft: window.innerWidth<= 768 ? '2vh':'30vh'}}>
                  <div className="section-title">Terms and Access Control</div>
                  <div className="input-row">
                    <div className="input-group">
                      <label className="input-label">User name*</label>
                      <input type="text" className="input-field"
                        value={user_name}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Add user name"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                    </div>
                    <div className="input-group">
                      <label className="input-label">Password</label>
                      <input type={showPassword ? "text" : "password"}
                        className="input-field"
                        value={user_password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Type password"
                        onKeyDown={(e) => e.stopPropagation()} 
                         />
                      <button type="button" className="password-toggle" onClick={togglePassword}>
                        {showPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
                      </button>
                    </div>
                    <div className="input-group">
                      <label className="input-label">Role*</label>
                      <select className="input-field" type="select"
                        value={role} onChange={(e) => setRole(e.target.value)} >
                        <option value="">Select Role</option>
                        {roles.map((r) => (
                          <option key={r.role_id} value={r.role_id}>{r.role_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input-group">
                    <label className="input-label">Bio</label>
                    <textarea
                      className="input-field address-input"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Add Bio..."
                      
                    />
                  </div>
                  <div className="buttons">
                    <button className="outline-button" onClick={()=> {handleClose2()}}>Cancel</button>
                    <button className="filled-button">Save Changes</button>
                  </div>
                </div>
              </div>
            </form>
            
            {showModal && <UploadStatusModal message={modalMessage} onClose={closeModal} />}
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open2, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open2 }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open2: PropTypes.bool,
};

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 100000;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'Manrope';
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden; /* Asegúrate de que no se desborde por defecto */
    background-color: rgb(12, 12, 12);
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: white;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);
