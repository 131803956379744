import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css, color, height} from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import UnstyledSelectIntroduction from './Select-modal';

import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import Cookies from 'js-cookie';
import { message, Upload } from 'antd';
import { Button } from '@mui/material';
const { Dragger } = Upload;


export default function CreateFolderModal() {
  const userII = JSON.parse(Cookies.get('userInfo'))
  const token = userII.user_id;
  const [open, setOpen] = React.useState(false);
  const [folderName, setFolderName] = React.useState(''); // Controlled state for folder name
  const [roleId, setRoleId] = React.useState(null); // Controlled state for role ID
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modelUStyle, setModalUStyle] = React.useState({});
  const [folders, setFolders] = React.useState({});
  const [isPhone, setIsPhone] = React.useState(false);
  const [folderID, setFolderID] = React.useState('');
  const [selectedFolder, setSelectedFolder] = React.useState(''); // New state for selected folder
  
  const props = {
    name: 'file',
    multiple: true,
    action: `http://35.223.83.66:1000/api/UploadFile`,
    data:{
      user_id: token,
      folderId: folderID
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const fetchCreateFolder = async (folderName, userId, roleId) => {
    try {
      let data = {
        folderName: folderName,
        userId: userId,
        roleId: roleId
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.goldenowlsagency.com/CreateGFolde', // Corrected URL typo
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      const response = await axios.request(config);
      console.log(response.data);
      message.success('Folder created successfully');
    } catch (error) {
      console.log(folderName, userId, roleId);
      message.error("Sorry but this didn't work");
    }
  };

  const handleCreateFolder = () => {
    const userId = token; // Assuming token is userId
    if (folderName && roleId) {
      fetchCreateFolder(folderName, userId, roleId); // Call the API on button click
    } else {
      message.error('Please fill out all fields');
    }
  };
  
  React.useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth > 768) {
        setModalUStyle({
            width: 900,
            height: 600,
            backgroundColor: 'rgb(12,12,12)',
            borderColor: 'rgb(24,24,24)',
            color:'white',
            fontFamily:'Manrope',
            ', .modal-description .ant-upload-text':{
                color: 'white'
            }, '.modal-title':{
                
            }
        });
        setIsPhone(false)
      } else {
        setModalUStyle({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%', 
          height: 450, // Ajustamos el tamaño para móviles
          backgroundColor: 'rgb(12,12,12)',
          color: 'white',
        });
        setIsPhone(true)
      }
    }
    updateStyle();

    window.addEventListener('resize', updateStyle);

    return () => window.removeEventListener('resize', updateStyle)
 
},[])


  return (
    <div>
      <TriggerButton type="button" onClick={handleOpen}>
        Create Folder
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={modelUStyle}>
          <h2 id="unstyled-modal-title" className="modal-title">
            Create a New Folder
          </h2>

        

            <FormControl defaultValue="" required>
                <Label>Folder Name</Label>
                <StyledInput placeholder="Write your name here" value={folderName} onChange={(e) => setFolderName(e.target.value)} // Update folder name
              required/>
                <HelperText />
                  
                <h5 style={{paddingTop: window.innerWidth <= 768 ? '2vh':'5vh'}}> Select the role Folder</h5>
            </FormControl>
            <div style={{position:'absolute', top: window.innerWidth <= 768 ?'40%' :'35%'}}><UnstyledSelectIntroduction setRoleId={setRoleId}/></div> 

            <div style={{position:'absolute', top: window.innerWidth <= 768? '60%':'50%'}}><Button variant="contained" sx={{
               
                backgroundColor: 'rgb(239, 180, 30)',
                '&:hover': {
                  backgroundColor: 'black',
                },
              }} onClick={handleCreateFolder}>Create Folder</Button>
            </div>

        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const upload = {
    '.ant-upload-text':{
        color: 'white'
    }
}

const drager = {
     width: 300, 
     backgroundColor:'rgb(24,24,24)' , 
     borderRadius: '25px',
     color: 'white',
    }


const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.7);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[50] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[50] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);

const StyledInput = styled(Input)(
    ({ theme }) => `
  
    .${inputClasses.input} {
      width: 320px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color: rgb(239, 180, 30);
      background: rgb(24,24,24);
      border: 1px solid rgb(239, 180, 30);
  
      &:hover {
        border-color:  rgb(239, 180, 30);
      }
  
      &:focus {
        outline: 0;
        border-color: rgb(239, 180, 30);
        box-shadow: 0 0 0 3px  rgb(239, 180, 30);
      }
    }
  `,
  );
  
  const Label = styled(({ children, className }) => {
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = React.useState(false);
  
    React.useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);
  
    if (formControlContext === undefined) {
      return <p>{children}</p>;
    }
  
    const { error, required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;
  
    return (
      <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
        {children}
        {required ? ' *' : ''}
      </p>
    );
  })`
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    margin-bottom: 4px;
    &.invalid {
      color: red;
    }
  `;
  
  const HelperText = styled((props) => {
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = React.useState(false);
  
    React.useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);
  
    if (formControlContext === undefined) {
      return null;
    }
  
    const { required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;
  
    return showRequiredError ? <p {...props}>This field is required.</p> : null;
  })`
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
  `;
  
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };