import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "./slider";
import Icon_menu from "./icon_menu";
import './css/recents.css';


const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// eslint-disable-next-line no-undef
const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const RecenChan = () => {
  const token = localStorage.getItem('token');
  const [Changes, setChanges] = useState([]);
  const [filteredChanges, setFilteredChanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("recent");
  const [showSortOptions, setShowSortOptions] = useState(false);
  const filesPerPage = 7;

  const FetchChanges = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://goldenowlsapi-testing.up.railway.app/api/Changes`,
        headers: {}
      };

      const response = await axios(config);
      setChanges(response.data[0]);
      setFilteredChanges(response.data[0]);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchChanges();
  }, [token]);

  useEffect(() => {
    let results = Changes.filter(change =>
      change.Name.toLowerCase().includes(filter.toLowerCase()) ||
      change.Details.toLowerCase().includes(filter.toLowerCase()) ||
      change.Date_Time.toLowerCase().includes(filter.toLowerCase()) ||
      change.Made_By.toLowerCase().includes(filter.toLowerCase())
    );

    if (sortOrder === "alphabeticalAsc") {
      results = results.sort((a, b) => a.Name.localeCompare(b.Name));
    } else if (sortOrder === "alphabeticalDesc") {
      results = results.sort((a, b) => b.Name.localeCompare(a.Name));
    } else if (sortOrder === "recent") {
      results = results.sort((a, b) => {
        const dateA = new Date(a.Date_Time.split('-').reverse().join('-'));
        const dateB = new Date(b.Date_Time.split('-').reverse().join('-'));
        return dateB - dateA;
      });
    } else if (sortOrder === "oldest") {
      results = results.sort((a, b) => {
        const dateA = new Date(a.Date_Time.split('-').reverse().join('-'));
        const dateB = new Date(b.Date_Time.split('-').reverse().join('-'));
        return dateA - dateB;
      });
    }
    

    setFilteredChanges(results);
    setCurrentPage(1); // reset to first page when filter changes
  }, [filter, Changes, sortOrder]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const totalPages = Math.ceil(filteredChanges.length / filesPerPage);
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = filteredChanges.slice(indexOfFirstFile, indexOfLastFile);

  const handleSortOptionClick = (order) => {
    setSortOrder(order);
    setShowSortOptions(false);
  };

  return (
    <div className="recents">
      <div className="search-bar">
          
          <input
            src={images['search-normal.png']}
            type="text"
            placeholder="Search..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      <h1>Recent Changes</h1>
      <div className="activity-container">
        <h1>Activity</h1>
        <p>Here you will find the recent changes, with all the modifications made to the system by users.</p>
        
        <div className="sort-by">
          <div className="sort-by-img"><img src= {images['filter.png']}	 alt="" />Filter</div>
          <button onClick={() => setShowSortOptions(!showSortOptions)}>Sort By {sortOrder} </button>
          {showSortOptions && (
            <div className="sort-options">
              <div onClick={() => handleSortOptionClick("recent")}>Recientes</div>
              <div onClick={() => handleSortOptionClick("oldest")}>Oldest</div>
              <div onClick={() => handleSortOptionClick("alphabeticalAsc")}>Alfabéticamente Ascendente</div>
              <div onClick={() => handleSortOptionClick("alphabeticalDesc")}>Alfabéticamente Descendente</div>
              
            </div>
          )}
        </div>
        <div className="back-black">
          <div className="cotent-table">
            <table className="activity-table">
              <thead>
                <tr>
                  <th className="th1">NAME</th>
                  <th>DETAILS</th>
                  <th>DATE</th>
                  <th className="th2">MADE BY</th>
                </tr>
              </thead>
              <tbody>
                {currentFiles.map((activity, index) => (
                  <tr key={index}>
                    <td>{activity.Name}</td>
                    <td>{activity.Details}</td>
                    <td>{activity.Date_Time}</td>
                    <td>{activity.Made_By}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="nav-move-pages2">
        <button className="next" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          <div className="next-text">Anterior</div>
        </button>
        <div className="page-numbers">
          {Array.from({ length: totalPages }, (_, index) => {
            const pageNumber = index + 1;
            if (
              pageNumber === 1 ||
              pageNumber === totalPages ||
              pageNumber === currentPage ||
              pageNumber === currentPage - 1 ||
              pageNumber === currentPage + 1
            ) {
              return (
                <span
                  key={index}
                  className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </span>
              );
            } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
              return <span key={index}>...</span>;
            }
            return null;
          })}
        </div>
        <button className="next" onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastFile >= filteredChanges.length}>
          <div className="next-text">Siguiente</div>
        </button>
      </div>
        </div>
      </div>
      
      <Icon_menu />
      <Slider />
    </div>
  );
};

export default RecenChan;
