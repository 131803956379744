import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const Thing = () => {
  const handleLoginSuccess = (response) => {
    const { credential } = response;
    console.log('Google Token:', credential);

    // Guarda el token en localStorage
    localStorage.setItem('googleToken', credential);
  };

  const handleLoginFailure = (error) => {
    console.error('Login Failed:', error);
  };

  return (
    <GoogleOAuthProvider clientId="42209266739-ga5tvj23977tsl1o65hk2ub642l79ngm.apps.googleusercontent.com">
      <div>
        <h2>Login con Google</h2>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default Thing;
