import React from 'react';
import './css/UploadStatusModal.css';

const UploadStatusModal = ({ message, onClose }) => {
  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  return (
    <div className="upload-status-modal-container">
      <div className="upload-status-modal-content">
        <h2>{message}</h2>
        <button className="btn btn-primary yellow-background black-color" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default UploadStatusModal;

