import React, { useEffect, useState, useCallback } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import { throttle } from 'lodash';  // o usa debounce si lo prefieres
import { useNavigate } from 'react-router-dom';
import './css/slider.css';
import MacBook from './MacBookAir1';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
};

// eslint-disable-next-line no-undef
const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const Slider = () => {
    const [isMacBookOpen, setIsMacBookOpen] = useState(false);
    const [isSliderVisible, setSliderVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [pages, setPages] = useState('');
    const adminStatus = localStorage.getItem('isAdmin') === 'true';

    const navegate = useNavigate();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [phone, setPhone] = useState(true);

    const handleOpenDocs = (params) => {
        if (params === 'Docs') {
            const link = 'https://docs.google.com/document/u/0/'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if(params === 'Sheets'){
            const link = 'https://docs.google.com/spreadsheets/u/0/'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if (params === 'Slides') {
            const link = 'https://docs.google.com/spreadsheets/u/0/'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if (params === 'Forms') {
            const link = 'https://docs.google.com/forms/u/0/'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if (params === 'Drive') {
            const link = 'https://drive.google.com/drive/u/0/home?ths=true'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if (params === 'Impact') {
            const link = 'https://login.ailife.com/ImpactMobile/SecureAuth.aspx?client_id=0de882b1506f4720b85e0e077bb931d1&redirect_uri=https%3A%2F%2Fimpactmobile.ailife.com%2F&response_type=id_token&scope=openid%20profile%20email&state=OpenIdConnect.AuthenticationProperties%3DI2OZDYGdHGLWqrVNcJ4xyvLdG5HL06kHpy7ywmZX3CHoZAl-qJRoYQAu6HWnGDITFV62Uwta7__z_YZ8jHHE3-kZfrmQ2taHXY7boFdf3Yues18PeWRd5v61A4E4K1gMPttzA-MH2yjIyLXJOAM7K7ctUGaAXEDdF_bkyiyUU64I0NC8qvHOxFbc4S5-QFvTI3aZVVuGMdval0xr4rc1O_PCoJCPc22RliMLtBp-rjA&response_mode=form_post&nonce=638668621158362133.ZTU0MDUyODAtMzQxNS00ZmM3LTlmMmUtNmUzM2U0NmIxZjM1ZDY1Y2U5NjQtOGY5NC00NzY2LTkyODQtNzkyMzI2M2FjNmUx&x-client-SKU=ID_NET461&x-client-ver=5.3.0.0'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else if (params === 'Scripts') {
            const link = 'https://workspace.google.com/products/apps-script/'
            const url = link;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            return null
        }
        
    }


    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        if (window.innerWidth>= 768) {
            setSliderVisible(true);
            setPhone(false);
            console.log(phone)
        } else {
            setSliderVisible(false);
            setPhone(true);
            console.log(phone)
        }
    };

    // Añadir y limpiar el event listener para el tamaño de la ventana
    useEffect(() => {
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Throttling para manejar el scroll y evitar que el slider se cierre accidentalmente
    const handleScroll = useCallback(throttle(() => {
        if (isSliderVisible) {
            // Lógica para manejar el scroll, ajusta según sea necesario
            console.log("Scrolling with slider open");
        }
    }, 200), [isSliderVisible]);

    // Añadir el event listener para el scroll solo cuando el slider esté visible
   

    useEffect(() => {
        if (isSliderVisible) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isSliderVisible]);
    
    const toggleSlider = () => {
        setSliderVisible(!isSliderVisible);
    };

    const openMacBookMenu = () => {
        setIsMacBookOpen(true);
        localStorage.setItem('userin', true)
    };

    const closeMacBookMenu = () => {
        setIsMacBookOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (adminStatus) {
            setPages('./admin');
        } else {
            setPages('./datos');
        }
    }, [adminStatus]);

    return (
        <div className="slider-container">
            {phone && (
                <IconButton aria-label="menu" onClick={toggleSlider} className="toggle-button" style={
                    {position: 'absolute',top: '1.5vh', left: '1vh',zIndex: 10000}}>  
                {isSliderVisible ? <MenuOpenIcon className='puto' style={{color: 'white', fontSize:'2.5rem'}} /> : <MenuIcon className='puto'  style={{color: 'white', fontSize:'2.5rem'}} />}
            </IconButton>
            )}
            {isSliderVisible && (
                <div className="slider">
                    <div className="slide-background_img"></div>
                    <div className="logo">
                        <a href="/WorkSpace"><img src={images['Logo.png']} alt="Golden Owl Logo" /></a> 
                    </div>
                    <div className="nav-bar">
                       
                            <div className='nav-bar1'>
                                <nav>
                                    <ul>
                                        <li className="option1">WORKSPACE</li>
                                        <li >
                                            <img src={images['chat.png']} alt="" />
                                            <a href=''>
                                                Chat
                                            </a>
                                        </li>

                                        <li>
                                            <img src={images['email.png']} alt="" />
                                            <a href='/Email'>
                                                Email
                                            </a>
                                        </li>

                                        <li onClick={()=> {handleOpenDocs('Scripts')}}>
                                            <img src={images['scripts.png']} alt="" />
                                            
                                                Scripts
                                            
                                        </li>

                                        <li>
                                            <img src={images['calendar.png']} alt="" />
                                            <a href='/Calendar'>
                                                Calendar
                                            </a>
                                        </li>

                                        <li>
                                            <img src={images['gvoice.png']} alt="" />
                                            <a href='https://voice.google.com/u/0/about'>
                                                Google Voice
                                            </a>
                                        </li>

                                        <li onClick={()=> {handleOpenDocs('Impact')}}>
                                            <img src={images['impact.png']} alt="" />
                                            
                                                Impact
                                           
                                        </li>
                                        
                                        <div className={`${isOpen ? 'menu-content' : ''}`}>
                                                <li onClick={toggleMenu}>
                                                    <img src={images['g.png']} alt="Recent Changes" />
                                                    Google WorkSpace
                                                    <span className="menu-arrow">{isOpen ? '▲' : '▼'}</span>
                                                </li>
                                                {isOpen && (
                                                    <div>
                                                        <li onClick={()=> {handleOpenDocs('Docs')}} className="submenu-item">Google Docs</li>
                                                        <li onClick={()=> {handleOpenDocs('Sheets')}} className="submenu-item">Google Sheet</li>
                                                        <li onClick={()=> {handleOpenDocs('Slides')}} className="submenu-item">Google Slides</li>
                                                        <li onClick={()=> {handleOpenDocs('Forms')}} className="submenu-item">Google Forms</li>
                                                        <li onClick={()=> {handleOpenDocs('Drive')}} className="submenu-item">Google Drive</li>
                                                    </div>
                                                )}
                                            </div>

                                        <li>
                                            <img src={images['files.png']} alt="" />
                                            <a href='/Files'>
                                                Files
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="admin-panel">
                                </div>
                            </div>
                        
                        <nav className='nav-bar22'>
                            <ul className="nav-bar2">
                                <li className="option2">HELP</li>
                                <li>
                                    <img src={images['Message.png']} alt="Message" /> Message
                                </li>
                                <li>
                                    <img src={images['support.png']} alt="Support" /> Support
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {isMacBookOpen && <MacBook onClose={closeMacBookMenu} />}
                </div>
            )}
        </div>
    );
};

export default Slider;
