import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // Para interacción con eventos
import axios from 'axios'; // Para solicitudes a la API
import { Modal, Button } from 'react-bootstrap'; // Bootstrap para el modal
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar estilos de Bootstrap
import Form from "react-bootstrap/Form";

import Slider from './slider';
import './css/calendar.css';
import Icon_menu from './icon_menu';
import timeGridPlugin from '@fullcalendar/timegrid'
import Cookies from 'js-cookie'
import { Mosaic } from 'react-loading-indicators';

export default function Calendar() {
    const userInfo = JSON.parse(Cookies.get('userInfo'));
    const token = userInfo.user_id;
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null); // Evento seleccionado
    const [showModal, setShowModal] = useState(false); // Estado del modal
    const [showModal2, setShowModal2] = useState(false); // Estado del modal
    const [loading, setLoading] = useState(true);
    const [newEvent, setNewEvent] = useState({
        title: "",
        start: "",
        end: "",
        description: "",
    }); // Detalles del evento nuevo

    const colorMapping = {
        1: '#ff5e00',  // Naranja
        2: '#16c79a',  // Verde Claro
        3: '#9c27b0',  // Purpura
        4: '#f54ea4',  // Rosado
        5: '#0062cc',  // Azul
        6: '#ffb60a',  // Amarillo
        7: '#4e9f3d',  // Cian
        8: '#0e0e0e',  // Gris
        9: '#000000',  // Negro
        10: '#02a502', // Verde
        11: '#9e1b32'  // Rojo
    };
    // Función para obtener los eventos desde tu API
    const fetchCalendarEvents = async () => {
        try {
            const response = await axios.post("https://api.goldenowlsagency.com/get-calendar", {
                userId: token, // Ejemplo de payload
            });
            
            // Transformar los eventos al formato de FullCalendar
            const transformedEvents = response.data.map(event => ({
                title: event.title,
                start: event.start,
                end: event.end,
                description: event.description,
                color: colorMapping[event.colorId] || '#2196f3',
                calendarId: event.calendarId,
                eventId:event.eventId,
            }));

            setEvents(transformedEvents);
            //await console.log(events)
        } catch (error) {
            console.error("Error fetching calendar events:", error);
        }
    };

    // Función para manejar el clic en un evento
    const handleEventClick = async (info) => {
        await setSelectedEvent(info.event);
        setShowModal(true); // Mostrar el modal
        ///await console.log(info.extendedProps.eventId)
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setShowModal(false);
        setSelectedEvent(null);
        
    };

    // Obtener eventos al cargar el componente
    useEffect(() => {
        const fetchData = async () =>{
            await fetchCalendarEvents();
            setLoading(false);
        }
        fetchData();
    }, []);

    const deleteEvent = async (event) => {
        if (!event) return;
    
        const confirmDelete = window.confirm(
            `¿Estás seguro de que quieres eliminar el evento "${event.title}"?`
        );
    
        if (confirmDelete) {
            try {
                await axios.post("https://api.goldenowlsagency.com/delete-event", {
                    userId: token,
                    calendarId: event?.extendedProps?.calendarId,
                    eventId: event?.extendedProps?.eventId,
                });
                alert("Evento eliminado con éxito.");
                closeModal();
                // Aquí puedes refrescar los eventos en el calendario
                fetchCalendarEvents();
            } catch (error) {
                console.error("Error al eliminar el evento:", error);
                alert("No se pudo eliminar el evento. Inténtalo de nuevo.");
            }
        }
    };
    
    const handleDateClick = (info) => {
        setNewEvent({ ...newEvent, start: info.dateStr, end: info.dateStr }); // Fecha seleccionada
        setShowModal2(true); // Mostrar modal
    };

    const handleSaveEvent = () => {
        setEvents([...events, newEvent]); // Agregar evento nuevo
        setShowModal2(false); // Cerrar modal
    };

    if (userInfo.if_login === 0) {
        return (
          <div className='email-no-login'>
            <div className='content-email-not'>
              <h1>Content not available </h1>
              <p>Please login with google to see this content</p>
              <a href='/UserInfo'>Click Here 👈</a>
            </div>
            
            <Slider />
            <Icon_menu />
          </div>
        )
      } else if (loading) {
        return (
            <div className='loading'><Mosaic color="rgb(249,180,30)" size="large" text="Loading..." textColor="" /></div>
        )
      }

    return (
        <div className='Calendar-Main' style={{ }}>
            <div className='Calendar' style={{ }}>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    initialView="dayGridMonth"
                    selectable
                    events={events} // Eventos obtenidos
                    eventClick={handleEventClick} // Manejador de clic en eventos
                    headerToolbar={{
                        start: 'prev,next today',
                        center: 'title',
                        end: 'dayGridMonth,dayGridWeek,timeGridDay',

                    }}
                    titleFormat={{ year: 'numeric', month: 'long' }} // Formato del título
                    buttonText={{
                        today: 'Today',
                        month: 'Month',
                        week: 'week',
                        day: 'Day',
                    }}
                    height="auto"
                      dateClick={handleDateClick}
                      navLinks
                      dayMaxEventRows={3}
                    
                />
            </div>

            {/* Modal para mostrar los detalles del evento */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedEvent?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Inicio:</strong>{" "}
                        {selectedEvent?.start &&
                            new Intl.DateTimeFormat("es-ES", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                            }).format(new Date(selectedEvent.start))}
                    </p>
                    <p>
                        <strong>Fin:</strong>{" "}
                        {selectedEvent?.end &&
                            new Intl.DateTimeFormat("es-ES", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                            }).format(new Date(selectedEvent.end))}
                    </p>
                    <p>
                        <strong>Descripción:</strong>{" "}
                        {selectedEvent?.extendedProps?.description || "No hay descripción"}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteEvent(selectedEvent)}>
                        Eliminar Evento
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>


              {/* Modal para crear evento */}
            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Crear Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formEventTitle">
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Introduce el título del evento"
                                value={newEvent.title}
                                onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEventStart" className="mt-3">
                            <Form.Label>Inicio</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={newEvent.start ? new Date(newEvent.start).toISOString().slice(0, 16) : ""}
                                onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEventEnd" className="mt-3">
                            <Form.Label>Fin</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={newEvent.end}
                                onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEventDescription" className="mt-3">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Detalles del evento"
                                value={newEvent.description}
                                onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSaveEvent}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Slider/>
            <Icon_menu/>
        </div>
    );
}
