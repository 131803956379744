import React, { useState, useEffect } from "react";
import { Button, ButtonBase } from "@mui/material";
import UploadModal from "./pages/UploadFileModal";
import Slider from "./pages/slider";
import Icon_menu from "./pages/icon_menu";
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import TextField from '@mui/material/TextField';
import './pages/css/Files.css';
import CreateFolderModal from "./pages/files/createFolderModal";
import DownloadIcon from '@mui/icons-material/Download';
import DownloadProgress from "./pages/files/DownloadProgress";

import Cookies from 'js-cookie'

import UnstyledSelectIntroduction from "./pages/files/Select-modal";
import { message } from "antd";
import FolderList from "./pages/files/fileList";
import EditFileModal from "./pages/files/FileListModal";
import FileListModal from "./pages/files/FileListModal";
import { useNavigate } from 'react-router-dom';


const customButton = {
  backgroundColor :'rgb(249,180,30)',
  '&:hover': {
          backgroundColor: 'rgb(249,180,30)',
              }
}

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// eslint-disable-next-line no-undef
const images = importAll(require.context('./pages/image', false, /\.(png|jpe?g|svg)$/));

const Logs = () => {
  const navigate = useNavigate(); // Hook para redirigir
  const userII = JSON.parse(Cookies.get('userInfo'))
  const token = userII.user_id;

  const [filteredChanges, setFilteredChanges] = useState([]); // Almacena los archivos del folder
  const [filteredChanges2, setFilteredChanges2] = useState([]);

  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);

  const [modalStyle, setModalStyle] = useState({}); // Estado para manejar estilos del modal
  const [isPhone, setIsPhone] = useState(false);
  const [folderID, setFolderID] = useState('');
  const [folders, setFolders] = useState([]);
  const [openFolder, setOpenFolder] = useState(false)

  const filesPerPage = 6;
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = filteredChanges.slice(indexOfFirstFile, indexOfLastFile);

  // Folders
  const indexOfLastFolder = currentPage2 * filesPerPage;
  const indexOfFirstFolder = indexOfLastFolder - filesPerPage;
  const currentFolders = filteredChanges2.slice(indexOfFirstFolder, indexOfLastFolder);

  const drager = {
    width: 300,
    backgroundColor: 'rgb(24,24,24)',
    borderRadius: '25px',
    color: 'white',
  };

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await axios.get(`https://api.goldenowlsagency.com/ListFolder/${token}`);
        if (Array.isArray(response.data) && response.data.length > 1) {
          setFolders(response.data);
          setFilteredChanges2(response.data); // Datos de folders
          //console.log(folders)
        } else {
          console.error('Error fetching folders:', response.data);
        }
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    };
    fetchFolders();
   
  }, []);

  const handleFolderClick = () => {
    if(openFolder == false){
      setOpenFolder(true); // Set the clicked folder
    } else {
      setOpenFolder(false); // Set the clicked folder
    }
    //message.info('The variable is ' + openFolder)
  };

  useEffect(() => {
    if (folderID) {  // Solo hacer fetch si hay un folderID seleccionado
      const fetchFolderFiles = async () => {
        try {
          const response = await axios.get(`https://api.goldenowlsagency.com/ListFilesFolders/${token}/${folderID}`);
          if (Array.isArray(response.data) && response.data.length > 0) {
            setFilteredChanges(response.data); // Almacena los archivos obtenidos
          } else {
            setFilteredChanges([]); // Limpia los archivos si no hay resultados
            console.error('No files found for the selected folder:', response.data);
          }
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      };
      fetchFolderFiles();
    }
  }, [folderID]); // Ejecuta esta función cada vez que folderID cambie


  useEffect(() => {
    const fetchAudits = async () => {
        try {
            const response = await axios.get('https://api.goldenowlsagency.com/get-audits');
            setFilteredChanges(response.data.data); // Asume que la API devuelve los datos en `response.data`
        } catch (error) {
            console.error('Error fetching audits:', error);
        } 
    };

    fetchAudits();
}, []);

  useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth > 768) {
        setModalStyle({
          position: 'absolute',
          marginTop: '1%',
          marginLeft: '19%'
        });
        setIsPhone(false);
      } else {
        setModalStyle({
          position: 'absolute',
          top: '9%',
          left: '19%',
          transform: 'translate(-50%, -50%)',
          width: '90%',  // Ajustamos el tamaño para móviles
          color: 'white',
        });
        setIsPhone(true);
      }
    };

    updateStyle();
    window.addEventListener('resize', updateStyle);
    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  const totalPages = Math.ceil(filteredChanges.length / filesPerPage);

  const handleDownload = async (fileId) => {

    try {
        setDownloading(true);
        
        const metadataResponse = await axios.post('https://api.goldenowlsagency.com/getFileMetadata', {
          userId: token,
          fileId,
      });

    const fileName = metadataResponse.data.name;
    console.log(fileName)

        const response = await axios.post('https://api.goldenowlsagency.com/downloadGoogleFile', {
            userId:token,

            fileId,
        }, {
            responseType: 'blob', // Esto asegura que la respuesta sea tratada como un blob
            onDownloadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted); // Update progress
            },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Usa el nombre del archivo obtenido

        document.body.appendChild(link);

        // celan up the temporary elements
        document.body.removeChild(link)
        ;
        link.click();
        link.remove();
        
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

const getFileIcon = (fileType) => {
  switch (fileType) {
    case 'pdf':
      return images['pdf.png']; // Cambia a la ruta de tu icono PDF
    case 'doc':
    case 'docx':
      return images['docx.png']; // Cambia a la ruta de tu icono Word
    case 'jpg':
    case 'jpeg':
    case 'png':
      return images['png.png']; // Cambia a la ruta de tu icono de imagen
    case 'mp4':
      return 'path/to/video-icon.png'; // Cambia a la ruta de tu icono de video
    case 'exe':
      return images['exe.png'];
    case 'txt':
      return images['txt.png'];
    case 'pptx' :
      return images['ppt.png'];
    case 'xlsx':
      return images['xsl.png'];
    default:
      return images['scripts.png']; // Icono por defecto
  }
};
const handleFolderClickWithId = (folderId) => {
  setFolderID(folderId);
  handleFolderClick();
};
const folderStyle = { cursor: 'pointer' }; // Avoid using inline styles repeatedly

  return (
    <div className='files-container'>
      <h1 className="main-title-files">Logs</h1>
      <div className="main-content-files">
        <h2>Logs</h2> 
        
        
        
        
         <div className={openFolder ? "back-button": "hidden-back-button"}> <Button onClick={() => {handleFolderClick();}} variant="contained" sx={{
          width:'60 px',
          height: '40px',
          backgroundColor: 'rgb(249, 180, 30)',
          '&:hover': {
                  backgroundColor: 'black',
                }
          }} >Back</Button>
        </div>
        {/* Documentos */}
        <div className="folder-files-container">
          <div className="content-table">
            
              <table className="activity-table">
              <thead>
                <tr>
                  <th className="th1">Action</th>
                  <th>Description</th>
                  <th className="th2">MORE</th>
                </tr>
              </thead>
              <tbody>
                {currentFiles.map((file, index) => (
                  <tr key={index}>
                    <td  style={{maxWidth: '350px'}}>
                      {file.Action}</td>
                    <td >{file.Description}</td>
                    
                    <td><FileListModal fileId={file.id} fileType={file.type}/></td>
                  </tr>
                ))}
              </tbody>
            </table>
           

            
          </div>
            
              <div className="nav-move-pages2" style={{}}>
            <button className="next" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
              <div className="next-text">Anterior</div>
            </button>
            <div className="page-numbers">
              {Array.from({ length: totalPages }, (_, index) => {
                const pageNumber = index + 1;
                if (
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  pageNumber === currentPage ||
                  pageNumber === currentPage - 1 ||
                  pageNumber === currentPage + 1
                ) {
                  return (
                    <span
                      key={index}
                      className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
                      onClick={() => setCurrentPage(pageNumber)}
                    >
                      {pageNumber + '  '}
                    </span>
                  );
                } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                  return <span key={index}>...</span>;
                }
                return null;
              })}
            </div>
            <button className="next" onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastFile >= filteredChanges.length}>
              <div className="next-text">Siguiente</div>
            </button>
          </div>
               
          
        </div>
      </div>
      <Slider />
      <Icon_menu />
      
    </div>
  );
};


export default Logs;
