import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

  // eslint-disable-next-line no-undef
  const images = importAll(require.context('../pages/image', false, /\.(png|jpe?g|svg)$/));


export default function LastFileModal({userID}) {
    const userII = JSON.parse(Cookies.get('userInfo'));
    const token = userII.user_id;
    const [fileData, setFileData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const navigate = useNavigate(); // Hook para redirigir


    const getFileIcon = (fileType) => {
        switch (fileType) {
          case 'pdf':
            return images['pdf.png']; // Cambia a la ruta de tu icono PDF
          case 'doc':
          case 'docx':
            return images['docx.png']; // Cambia a la ruta de tu icono Word
          case 'jpg':
          case 'jpeg':
          case 'png':
            return images['png.png']; // Cambia a la ruta de tu icono de imagen
          case 'mp4':
            return 'path/to/video-icon.png'; // Cambia a la ruta de tu icono de video
          case 'exe':
            return images['exe.png'];
          case 'txt':
            return images['txt.png'];
          case 'pptx' :
            return images['ppt.png'];
          case 'xlsx':
            return images['xsl.png'];
          default:
            return images['scripts.png']; // Icono por defecto
        }
      };

    React.useEffect(() => {
        // Definir la función para obtener datos del archivo
        const fetchLastFile = async () => {
            try {
                const response = await fetch('https://api.goldenowlsagency.com/last-file', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: token })
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const data = await response.json();
                setFileData(data.data); // Guardar datos del archivo en el estado
            } catch (error) {
                console.error('Error fetching last file:', error);
                setError(error.message); // Guardar error en el estado
            }
        };

        // Llamar a la función
        fetchLastFile();
    }, []);

    const handleGoToFiles = () => {
        navigate('/Files'); // Cambia '/files' por la ruta correcta si es diferente
    };

    const handleDownload = async (fileId) => {
        try {
            
            const metadataResponse = await axios.post('https://api.goldenowlsagency.com/getFileMetadata', {
              userId: token,
              fileId,
          });
    
        const fileName = metadataResponse.data.name;
        //console.log(fileName)
    
            const response = await axios.post('https://api.goldenowlsagency.com/downloadGoogleFile', {
                userId:token,
    
                fileId,
            }, {
                responseType: 'blob', // Esto asegura que la respuesta sea tratada como un blob
                onDownloadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Usa el nombre del archivo obtenido
    
            document.body.appendChild(link);
    
            // celan up the temporary elements
            document.body.removeChild(link)
            ;
            link.click();
            link.remove();
            
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(12,12,12)',
        borderRadius: 4, // Bordes redondeados
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // Sombra personalizada
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.05)', // Efecto de agrandamiento al pasar el mouse
        },
      }}
    >
        {error ? (
                <p>Error: {error}</p>
            ) : fileData ? (
                <div style={{ textAlign: 'center'}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="60" // Cambiado para hacer la imagen un poco más alta
          image={getFileIcon(fileData.mimeType)}
          alt="green iguana"
          sx={{
            position: 'absolute',
            borderRadius: '4px 4px 0 0', // Bordes redondeados solo en la parte superior
            width: window.innerWidth >= 768 ? '10vh' : '100px',
            left: window.innerWidth >= 768 ? '43%' : '36%',
            top:window.innerWidth >= 768 ? '45%' : '45%'
          }}
        />
        <CardContent
          sx={{
            backgroundColor: 'rgb(12,12,12)', // Fondo más claro en el contenido
            padding: 3,
          }}
        >
             
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ color: 'rgb(249,180,30)', fontWeight: 'bold' }} // Texto personalizado
                    >
                        Last File Uploaded
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', lineHeight: 1.5 ,}}
                    >
                    <p style={{
                        position: 'relative',
                        color:'rgb(249,180,30)',
                        bottom: window.innerWidth >= 768 ?'-13vh' :'-15vh',
                        
                        fontSize: '2.5vh'
                    }}
                        >Name: {fileData.name}</p>
                    </Typography>
                    </CardContent>
                    </CardActionArea>
                </div>
            ) : (
                <p>Cargando...</p>
            )}
          
       
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between', // Espacio entre los botones
          padding: 2,
        }}
      >
        <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {handleDownload(fileData?.id)}}
            sx={{
                position: 'absolute',
                bottom: '5%',
                left: '3vh',
                width : window.innerWidth >=768 ? 'none': '100px',
                fontWeight: 'bold',
                color: 'rgb(249,180,30)',
                borderColor: 'rgb(249,180,30)',
                '&:hover': {
                backgroundColor: 'black',
                color: 'rgb(249,180,30)', // Texto de nuevo color sobre fondo negro
                borderColor: 'black',
                },
                '&.Mui-selected': {
                backgroundColor: 'black',
                color: 'rgb(249,180,30)',
                borderColor: 'black',
                },
            }}
            >
            Download
        </Button>
        <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleGoToFiles}
            sx={{
                position: 'absolute',
                bottom: '5%',
                right: '3vh',
                width : window.innerWidth >=768 ? 'none': '150px',
                backgroundColor: 'rgb(249,180,30)',
                color: 'black',
                '&:hover': {
                backgroundColor: 'black',
                color: 'rgb(249,180,30)',
                },
                '&.Mui-selected': {
                backgroundColor: 'black',
                color: 'rgb(249,180,30)',
                },
            }}
            >
            Go File
        </Button>
      </CardActions>
    </Card>
  );
}
