import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css, color} from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import Cookies from 'js-cookie'
import { message, Upload } from 'antd';
import { data } from 'autoprefixer';
const { Dragger } = Upload;


export default function UploadModal() {
  const userII = JSON.parse(Cookies.get('userInfo'))
  const token = userII.user_id;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modelUStyle, setModalUStyle] = React.useState({});
  const [folders, setFolders] = React.useState({});
  const [isPhone, setIsPhone] = React.useState(false);
  const [folderID, setFolderID] = React.useState('');
  const [selectedFolder, setSelectedFolder] = React.useState(''); // New state for selected folder

  
  const props = {
    name: 'file',
    multiple: true,
    action: `https://api.goldenowlsagency.com/UploadFile`,
    data:{
      user_id: token,
      folderId: folderID
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  React.useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth > 768) {
        setModalUStyle({
            width: 900,
            height: 600,
            backgroundColor: 'rgb(12,12,12)',
            borderColor: 'rgb(24,24,24)',
            color:'white',
            fontFamily:'Manrope',
            ', .modal-description .ant-upload-text':{
                color: 'white'
            }, '.modal-title':{
                
            }
        });
        setIsPhone(false)
      } else {
        setModalUStyle({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',  // Ajustamos el tamaño para móviles
          backgroundColor: 'rgb(12,12,12)',
          color: 'white',
        });
        setIsPhone(true)
      }
    }
    updateStyle();

    window.addEventListener('resize', updateStyle);

    return () => window.removeEventListener('resize', updateStyle)
 
},[])

  React.useEffect(()=>{
    const fetchFolders = async () => {
      try {
        const response = await axios.get(`https://api.goldenowlsagency.com/ListFolder/${token}`);
        if (Array.isArray(response.data) && response.data.length > 1) {
          const gFolders = response.data; // Datos de nacionalidades
          setFolders(gFolders);
        } else {
          console.error('Error fetching nationalities and sex:', response.data);
        }
      } catch (error) {
        console.error('Error fetching nationalities and sex:', error);
      }
    };
    fetchFolders();
   
 
  },[])

  return (
    <div>
      <TriggerButton type="button" onClick={handleOpen}>
        New File
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={modelUStyle}>
          <h2 id="unstyled-modal-title" className="modal-title">
            Upload New File
          </h2>
          <h5 style={{color:'white'}} id="unstyled-modal-description" className="modal-description">Select the folder</h5>

          <Autocomplete
            disablePortal
            options={folders}
            autoHighlight
            getOptionLabel={(folders) => folders.folder_name}
            sx={drager}
            onChange={async (event, newValue) => { if (newValue) {
              setFolderID(newValue.folder_id);  // Update selected folder
              console.log('Selected Folder ID:', newValue.folder_id); 
            } else {
              setFolderID(null); // Clear selected folder if none is selected
            }}}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Folders"
                InputLabelProps={{
                    style: { color: 'white' }, // Changes the label color to white
                }}
                sx={{
                    '& .MuiInputBase-root': {
                    color: 'white', 
                    borderColor:'red'// Changes the input text color to white
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'none', // Changes the outline color to white
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgb(249,180,30)!important',
                    borderRadius:'25px' // Keeps the outline white on hover
                    },
                }}
                />
            )}
            />

        <div >
          <Dragger {...props} 
          style={{borderColor: 'rgb(249,180,30)'}}
          disabled={!folderID} >
            <p className="ant-upload-drag-icon">
            <InboxOutlined style={{color:'rgb(249,180,30)'}} />
            </p>
            <p style={{color: 'white'}} className="ant-upload-text">Click or drag file to this area to upload</p>
            <p style={{color: 'white'}} className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
            </p>
        </Dragger>
        </div>
          
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const upload = {
    '.ant-upload-text':{
        color: 'white'
    }
}

const drager = {
     width: 300, 
     backgroundColor:'rgb(24,24,24)' , 
     borderRadius: '25px',
     color: 'white',
    }

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);
