import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from './slider';
import './css/MacBookAir1.css';
import UploadStatusModal from './UploadStatusModal';  // Importa el componente del modal
import Icon_menu from './icon_menu'

const NewEmployee = () => {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [sex, setSex] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [nationality, setNationality] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState('');
  const [indetication_number, setIndeticationNumber] = useState('');
  const [address, setAddress] = useState(''); // Nuevo estado para la dirección
  const [roles, setRoles] = useState([]);
  const [sexes, setSexes] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [modalMessage, setModalMessage] = useState('');  // Estado para el mensaje del modal
  const [showModal, setShowModal] = useState(false);     // Estado para controlar la visibilidad del modal

  useEffect(() => {
    const fetchNationalitiesAndSex = async () => {
      try {
        const response = await axios.get('https://api.goldenowlsagency.com/GetNationalitiesAndSex');
        if (Array.isArray(response.data) && response.data.length > 1) {
          const nationalitiesData = response.data[0][0]; // Datos de nacionalidades
          const sexesData = response.data[1][0]; // Datos de sexos
          setNationalities(nationalitiesData);
          setSexes(sexesData);
        } else {
          console.error('Error fetching nationalities and sex:', response.data);
        }
      } catch (error) {
        console.error('Error fetching nationalities and sex:', error);
      }
    };

    fetchNationalitiesAndSex();

    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://api.goldenowlsagency.com/GetAllRoles');
        if (Array.isArray(response.data)) {
          setRoles(response.data);
        } else {
          console.error('Error fetching roles:', response.data);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const data = {
      firstName,
      middleName,
      lastName,
      email,
      phone,
      sex,
      birthdate,
      nationality,
      username,
      password,
      role,
      indetication_number,
      address, // Incluimos la dirección en los datos enviados al servidor
      admin_user: token // Usamos el token en lugar del valor estático
    };

    try {
      const response = await axios.post('https://api.goldenowlsagency.com/CreateUser', data);
      console.log(response.data);
      setModalMessage('New employee created successfully');
      setShowModal(true);
      // Aquí podrías añadir lógica adicional como notificaciones o redireccionamientos
    } catch (error) {
      console.error('Error creating user:', error);
      setModalMessage('Error creating user');
      setShowModal(true);
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNameChange = (e, setName) => {
    const value = e.target.value;
    if (value.length <= 20 && /^[a-zA-Z]*$/.test(value)) {
      setName(value);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div className="mac-book-air-1">
      <div className="header">
        <div className="users-header">Users</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-container">
          <div className="personal-information-section">
            <div className="section-title">Personal information</div>
            <div className="input-row">
              <div className="input-group">
                <label className="input-label">First Name*</label>
                <input type="text" className="input-field"
                  value={firstName}
                  onChange={(e) => handleNameChange(e, setFirstName)}
                  placeholder="Name"
                  required />
              </div>
              <div className="input-group">
                <label className="input-label">Middle Name*</label>
                <input type="text" className="input-field"
                  value={middleName}
                  onChange={(e) => handleNameChange(e, setMiddleName)}
                  placeholder="Middle Name"
                  required />
              </div>
              <div className="input-group">
                <label className="input-label">Last Name*</label>
                <input type="text" className="input-field"
                  value={lastName}
                  onChange={(e) => handleNameChange(e, setLastName)}
                  placeholder="Last Name"
                  required />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label className="input-label">Email*</label>
                <input type="email" className="input-field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Type email address"
                  required />
              </div>
              <div className="input-group">
                <label className="input-label">Phone*</label>
                <input type="tel" className="input-field"
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Type phone number"
                  required />
              </div>
              <div className="input-group">
                <label className="input-label">Gender</label>

                <select className="input-field" type="select"
                  value={sex} onChange={(e) => setSex(e.target.value)} required>
                  <option value="">Select Gender</option>
                  {sexes.map((s) => (
                    <option key={s.sex_id} value={s.sex_id}>{s.sex}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label className="input-label">Date of birth</label>
                <input type="date" className="input-field"
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                  required />
              </div>
              <div className="input-group">
                <label className="input-label">Nationality</label>
                <select class="input-field" type="select"
                  value={nationality} onChange={(e) => setNationality(e.target.value)} required>
                  <option value="">Select Nationality</option>
                  {nationalities.map((n) => (
                    <option key={n.nationality_id} value={n.nationality_id}>{n.nationality}</option>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label className="input-label">DNI or passport</label>
                <input type="text" className="input-field"
                  value={indetication_number}
                  onChange={(e) => setIndeticationNumber(e.target.value)}
                  placeholder="DNI"
                  required />
              </div>
            </div>
          </div>
          <div className="terms-and-access-section">
            <div className="section-title">Terms and Access Control</div>
            <div className="input-row">
              <div className="input-group">
                <label className="input-label">User name*</label>
                <input type="text" className="input-field"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Add user name"
                  required />
              </div>
              <div className="input-group">
                <label className="input-label">Password</label>
                <input type={showPassword ? "text" : "password"}
                  className="input-field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Type password"
                  required />
                <button type="button" className="password-toggle" onClick={togglePassword}>
                  {showPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
                </button>
              </div>
              <div className="input-group">
                <label className="input-label">Role*</label>
                <select className="input-field" type="select"
                  value={role} onChange={(e) => setRole(e.target.value)} required>
                  <option value="">Select Role</option>
                  {roles.map((r) => (
                    <option key={r.role_id} value={r.role_id}>{r.role_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-group">
              <label className="input-label">Bio</label>
              <textarea
                className="input-field address-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Add Bio..."
                required
              />
            </div>
            <div className="buttons">
              <button className="outline-button" >Cancel</button>
              <button className="filled-button">Add user</button>
            </div>
          </div>
        </div>
      </form>
      {showModal && <UploadStatusModal message={modalMessage} onClose={closeModal} />}
      <Icon_menu/>
      <Slider/>
    </div>
  );
}
export default NewEmployee;