import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { Button } from "@mui/material";
import { ImageIcon } from 'lucide-react';
import { Image, Upload, message  } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import axios from 'axios'; // Necesitas instalar axios para manejar solicitudes HTTP

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function NestedModal() {
  const [open, setOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [modalStyle, setModalStyle] = useState({}); // Estado para manejar estilos del modal
  const [isPhone, setIsPhone] = useState(false);
  const [imageList, setImageList] = useState([]);
  // Cargar las imágenes desde la carpeta /sliderimgs
  // eslint-disable-next-line no-undef
  useEffect(() => {
  const fetchImages = async () => {
    try {
      const response = await axios.get('https://api.goldenowlsagency.com/images');

      // Estructura `imageList` en el formato esperado por `Upload`
      const formattedImages = response.data.images.map((url) => ({
        uid: url,                // Usamos el URL como identificador único
        name: url.split('/').pop(), // El nombre del archivo de la URL
        status: 'done',           // Estado de la imagen como cargada
        url: url                  // URL absoluta de la imagen
      }));

      await setImageList(formattedImages); // Guardamos la lista de imágenes formateada
      console.log(formattedImages)
    } catch (err) {
      console.error("Error cargando las imágenes", err);
    }
  };

  fetchImages();
}, []);

  

  useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth > 768) {
        setModalStyle({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          backgroundColor: 'rgb(12,12,12)',
          color: 'white',
        });
        setIsPhone(false)
      } else {
        setModalStyle({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',  // Ajustamos el tamaño para móviles
          backgroundColor: 'rgb(12,12,12)',
          color: 'white',
        });
        setIsPhone(true)
      }
    };

    updateStyle();

    window.addEventListener('resize', updateStyle);

    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  const handleOpen =async () => {
    setOpen(true);
    await setFileList(imageList); // Inicializamos con las imágenes existentes
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleRemove = async (file) => {
    try {
      const response = await axios.delete('https://api.goldenowlsagency.com/delete-image', {
        data: { fileName: file.name }, // Aquí le pasamos el nombre del archivo
      });

      if (response.status === 200) {
        message.success('Imagen eliminada exitosamente');
      } else {
        message.error('No se pudo eliminar la imagen');
      }
    } catch (error) {
      message.error('Error al eliminar la imagen');
    }
  };

  // Función personalizada para subir las imágenes
  const customUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://api.goldenowlsagency.com/uploadSliderImg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        onSuccess("Ok");
        message.success('Imagen subida exitosamente');
      } else {
        message.error('No se pudo subir la imagen');
        onError(new Error('Error al subir la imagen'));
      }
    } catch (error) {
      message.error('Error al subir la imagen');
      onError(error);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
        color: 'white',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div>
      {isPhone ? (
        <Button
        variant="contained"
        startIcon={<ImageIcon />}
        onClick={handleOpen}
        sx={{
          marginTop:'0', 
          width:'100%', 
          fontSize:'1.2rem', 
          backgroundColor: '#F0B41E',
          '&:hover': {
            backgroundColor: '#FFCB37',
          },
        }}
      >
        Agregar Imágenes
      </Button>
      ):(
        <Button
        variant="contained"
        startIcon={<ImageIcon />}
        onClick={handleOpen}
        sx={{
          backgroundColor: '#F0B41E',
          '&:hover': {
            backgroundColor: '#FFCB37',
          },
        }}
      >
        Agregar Imágenes
      </Button>
      )}
      

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={modalStyle}>
          <h2 id="parent-modal-title" className="modal-title">
            Agregar Imágenes
          </h2>
          
          <Upload
            customRequest={customUpload}  // Usamos la función personalizada para la subida
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemove}
          >
            {fileList.length === 10 ? null : uploadButton}
          </Upload>

          {previewImage && (
            <Image
              wrapperStyle={{
                display: 'none',
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 900;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? '#1C2025' : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? '#434D5B' : '#C7D0DD'};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? '#F3F6F9' : '#1C2025'};
  `,
);
