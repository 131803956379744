import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth <= 768 ? 500: 1300,           // Set width to 1400px
    maxHeight: window.innerWidth <= 768 ? '85vh':'90vh',  
    height:700,   // Set max height to 80% of the viewport height
    overflowY: 'auto',     // Enable vertical scrolling if content exceeds max height
    scrollbarWidth: 'none', // Hide scrollbar (for Firefox)
    msOverflowStyle: 'none', // Hide scrollbar (for IE and Edge)
    '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar (for Chrome and Safari)
    bgcolor: 'rgb(12,12,12)',
    border: '2px solid rgb(249,180,30)',
    boxShadow: 24,
    p: 4,
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };
  
  // eslint-disable-next-line no-undef
  const images = importAll(require.context('../image', false, /\.(png|jpe?g|svg)$/));

export default function SeeUserInformation({ userId, openVar, onClose }) {
    const user2 = localStorage.getItem('userinfo')
    const adminStatus = localStorage.getItem('isAdmin') === 'true';
    const [imgUser, SetImgUser] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [profileImage, setProfileImage] = useState('');
    const [userName, setUserName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchUserInfo = async () => {
        try {
            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.goldenowlsagency.com/InfomrationUser/${userId}`,
                headers: {}
            };
            
            const response = await axios(config);
            setUserInfo(response.data[0][0]);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    };
    useEffect(() => {
        fetchUserInfo();
    }, [userId]);
    
  return (
    <div>
      <Modal
        open={openVar}
        onClose={onClose}  // Pass the function to close the modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div >
                <div className="user-info-body" style={{left: window.innerWidth <= 768 ? '-2vh':'-5vh', top:'-13vh' ,width:'100%', height: '95vh !important'}}>
                    <div className="user-info-body-int" style={{height:  window.innerWidth <= 768 ? '900px': ''}}>
                        <div className="my-profile" style={{marginTop: window.innerWidth <= 768 ? '6%': ''}}>Profile</div>
                        <div className="user-info-body-int-1">
                            <img src={userInfo?.user_img ? userInfo.user_img : images['usuario.png']} alt="User Avatar" />  
                            <div className="init-1-text1">{userInfo?.full_name}</div>
                            <div className="init-1-text2">Usuario: {userInfo?.role_name}</div>
                            <div className="google-login">
                            
                                
                            </div>
                        </div>
                        <div className="user-info-body-int-2">
                            <div className="personal_info">Personal Information</div>
                            <div className="init-2-text">
                                <p style={{color:'grey'}}>First Name<br /> <p><strong>{userInfo?.name}</strong></p></p>
                                <p className="p2" style={{color:'grey'}}>Middle Name<br /> <p><strong>{userInfo?.middle_name}</strong></p></p>
                                <p className="p3" style={{color:'grey'}}>Last Name<br /> <p><strong>{userInfo?.last_name}</strong></p></p>
                            </div>
                            <div className="init-3-text">
                                <p className ="pn" style={{color:'grey'}}>Email<br /> <p><strong>{userInfo?.email}</strong></p></p>
                                <p className="p4" style={{color:'grey'}}>Phone Number<br /> <p><strong>{userInfo?.phone_number}</strong></p></p>
                                <p className="p5" style={{color:'grey'}}>Gender<br /> <p><strong>{userInfo?.sex}</strong></p></p>
                            </div>
                            <div className="init-4-text">
                                <p style={{color:'grey'}}>Age<br /> <p><strong>{userInfo?.age}</strong></p></p>
                                <p className="p6" style={{color:'grey'}}>Nacionality<br /> <p><strong>{userInfo?.nationality}</strong></p></p>
                                <p className="p7" style={{color:'grey'}}>DNI-Passport<br /> <p><strong>{userInfo?.identification_number}</strong></p></p>
                            </div>
                            <div className="access_control">Access Control</div>
                            <div className="init-5-text">
                                <p style={{color:'grey'}}>User<br/> <p><strong>{userInfo?.user_name}</strong></p></p>
                                <p className="p8" style={{color:'grey'}}>Role<br/><p><strong>{userInfo?.role_name}</strong></p></p>
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            </Box>
            </Modal>
        </div>
      
  );
}
