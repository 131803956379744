import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from './slider';
import './userinfo.css';
import Icon_menu from './icon_menu'
import MacBook from './MacBookAir1';
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { GoogleLoginButton } from "react-social-login-buttons";
import getGoogleUserProfile from "../func/test";
import { Button } from "@mui/material";
import Cookies from "js-cookie"


const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };
  
  // eslint-disable-next-line no-undef
  const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const UserInfo = () => {
    const userII = JSON.parse(Cookies.get('userInfo'))
    const token = userII.user_id;
    const user2 = localStorage.getItem('userinfo')
    const adminStatus = localStorage.getItem('isAdmin') === 'true';
    const [imgUser, SetImgUser] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [profileImage, setProfileImage] = useState('');
    const [userName, setUserName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    const fetchUserInfo = async () => {
        try {
            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.goldenowlsagency.com/InfomrationUser/${token}`,
                headers: {}
            };
            
            const response = await axios(config);
            setUserInfo(response.data[0][0]);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    };

    const getImgUrl = () => {
        try{
            if (userII.user_img !== null){
                return userII.user_img;
            } else {
                return images['usuario.png']
            }
            
        } catch {
            return 'There is not a img'
        }
        
      };

    const fetchUserImage = async () => {
        const user_img = await getImgUrl();
        //console.log(user_img)
        await SetImgUser(user_img)
      };
      

    useEffect(() => {
        fetchUserInfo();
        fetchUserImage();
        console.log(imgUser)
    }, []);

    const  login = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/drive.appfolder https://www.googleapis.com/auth/drive   https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.addons.execute https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar',
        access_type: 'offline',

        flow: 'auth-code',
        //prompt: 'consent',
        onSuccess:  async ( response) =>{
        const credential = response.code;

        console.log(response)
        let data ={
        "userId": token,
        "code": credential,
        };

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.goldenowlsagency.com/UpdateGoogleToken',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        await axios.request(config)
        .then((response) => {
        const u_img = response.data[0].user_img;
        try {
            userII.user_img = u_img;
            userII.if_login = 1;

            Cookies.set('userInfo',JSON.stringify(userII))
        } catch {
            console.log('Something failed')
        }
        
        })
        .catch((error) => {
        console.log(error);
        });

        const user_img = getImgUrl();
        //console.log(user_img)
        SetImgUser(user_img)

        // Guarda el token en localStorage
        localStorage.removeItem('googleToken')
        await localStorage.setItem('googleToken', credential);
        window.location.reload();
      },
      });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div className="user-info">
                <div className="user-info-body">
                    <div className="user-info-body-int">
                        <div className="my-profile">My Profile</div>
                        <div className="user-info-body-int-1">
                            <img src={imgUser} alt="User Avatar" />
                            <div className="init-1-text1">{userInfo?.full_name}</div>
                            <div className="init-1-text2">Usuario: {userInfo.role_name}</div>
                            <div className="google-login">
                            
                                    <div>
                                       <Button variant="contained" onClick={()=> login()} 
                                        sx={{background:'rgb(249,180,30)',
                                            '&:hover':{
                                                backgroundColor: 'white',
                                                color: 'black'
                                            }
                                        }}>Sync with Google</Button>
                                    </div>
                                
                            </div>
                        </div>
                        <div className="user-info-body-int-2">
                            <div className="personal_info">Personal Information</div>
                            <div className="init-2-text">
                                <p style={{color:'grey'}}>First Name<br /> <p><strong>{userInfo.name}</strong></p></p>
                                <p className="p2" style={{color:'grey'}}>Middle Name<br /> <p><strong>{userInfo.middle_name}</strong></p></p>
                                <p className="p3" style={{color:'grey'}}>Last Name<br /> <p><strong>{userInfo.last_name}</strong></p></p>
                            </div>
                            <div className="init-3-text">
                                <p className ="pn" style={{color:'grey'}}>Email<br /> <p><strong>{userInfo.email}</strong></p></p>
                                <p className="p4" style={{color:'grey'}}>Phone Number<br /> <p><strong>{userInfo.phone_number}</strong></p></p>
                                <p className="p5" style={{color:'grey'}}>Gender<br /> <p><strong>{userInfo.sex}</strong></p></p>
                            </div>
                            <div className="init-4-text">
                                <p style={{color:'grey'}}>Age<br /> <p><strong>{userInfo.age}</strong></p></p>
                                <p className="p6" style={{color:'grey'}}>Nacionality<br /> <p><strong>{userInfo.nationality}</strong></p></p>
                                <p className="p7" style={{color:'grey'}}>DNI-Passport<br /> <p><strong>{userInfo.identification_number}</strong></p></p>
                            </div>
                            <div className="access_control">Access Control</div>
                            <div className="init-5-text">
                                <p style={{color:'grey'}}>User<br/> <p><strong>{userInfo.user_name}</strong></p></p>
                                <p className="p8" style={{color:'grey'}}>Role<br/><p><strong>{userInfo.role_name}</strong></p></p>
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="google-login2">
                <Button variant="contained" onClick={()=> login()} 
                                        sx={{background:'rgb(249,180,30)',
                                            '&:hover':{
                                                backgroundColor: 'white',
                                                color: 'black'
                                            }
                                        }}>Sync with Google</Button>
                            </div>
            </div>
            <Slider />
            <Icon_menu />

        </div>
        
    );
};

export default UserInfo;
