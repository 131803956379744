import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css, margin } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import '../users/DUM.css'
import Cookies from 'js-cookie'
import axios from 'axios';
import { message } from 'antd';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';



export default function EditFileModal({openMenu ,closeMenu, fileId }) {
  const userII = JSON.parse(Cookies.get('userInfo'));
  const token = userII.user_id;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeMenu();
};
  


const handleSend = async () => {
    try {
      // Realizar la petición a la API con el valor del TextField
      const response = await axios.post('https://api.goldenowlsagency.com/rename-file', {
        fileId: fileId,
        newFileName:inputValue,
        userId: token
      });
      if (response.data.message) {
        message.success('Rename Folder successfully');
        setTimeout(() => {
          window.location.reload(); // Reload the page after 1 seconds
        }, 1000);
      } else if (response.data.error) {
        message.error(response.data.error);
      } else {
        message.error('Unknown error occurred');
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openMenu}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 500 }}>
            <h2 id="unstyled-modal-title" className="modal-title" style={{textAlign: 'center'}}>
                Edit the name of the file  
            </h2>
            <div className='DeleteUserButtons'>
            <Box
                component="form"
                sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                noValidate
                autoComplete="off"
                >
                <TextField 
                    id="outlined-basic" 
                    label="File Name" 
                    variant="outlined" 
                    onChange={(e) => setInputValue(e.target.value)}
                    sx={{
                        input: {
                            color:'white', // Color del texto dependiendo del estado
                          },
                        backgroundColor: 'rgb(24, 24, 24)', 
                        color: 'white',
                        '& .MuiInputLabel-root': {
                        color: '#ffff!important', // Cambia el color del label a blanco
                        },
                        '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgb(249, 180, 30)',
                            color: 'white' // Color del borde en estado normal
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgb(249, 180, 30)',
                            color: 'white' // Color del borde al hacer hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'rgb(249, 180, 30)',
                            color: 'white' // Color del borde cuando está seleccionado
                        },
                        },
                    }}
                />
                <div style={{marginLeft: window.innerWidth<=768 ? '1%': '25%'}}>
                <Button
        onClick={handleSend}
        sx={{
          backgroundColor: 'rgb(249, 180, 30)', // Color RGB del botón
          color: 'black', // Color del texto en negro
          marginTop: '16px',
          '&:hover': {
            backgroundColor: 'rgba(249, 180, 30, 0.8)', // Efecto hover
          },
        }}
      >Send</Button>
      </div>
            </Box>
            </div>
          
        </ModalContent>
      </Modal>
      
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const DeleteButton = {

}


const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: rgb(12,12,12);
    border-radius: 8px;
    border: 1px solid rgb(249,180,30);
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: white;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
      color: white;
      font-size: 3vh;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

