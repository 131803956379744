import React, { useState } from 'react';
import './css/forgot-password.css';
import { useNavigate } from 'react-router-dom';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
};

const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const ForgotPassword = () => {
    const [userName, setUserName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSendRequest = async () => {
        console.log(`Sending request for user: ${userName}`);  // Debug: Check if function is called
        const apiUrl = `https://goldenowlsapi-testing.up.railway.app/api/changepassword/${userName}`;
        
        try {
            const response = await fetch(apiUrl);
            const contentType = response.headers.get("content-type");
            console.log(`Response content-type: ${contentType}`);  // Debug: Check the content type

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (contentType && contentType.indexOf("application/json") !== -1) {
                const responseData = await response.json();
                console.log(`Response JSON: ${JSON.stringify(responseData)}`);  // Debug: Check the parsed JSON
                if (responseData.message === 'Email sent successfully') {
                    navigate('/ResetConfirm');
                } else {
                    setErrorMessage('Error al enviar la solicitud. Inténtelo de nuevo más tarde.');
                }
            } else {
                const text = await response.text();
                console.log(`Response text: ${text}`);  // Debug: Check the response text

                if (text === '0') {
                    setErrorMessage('Usuario Inexistente');
                } else {
                    setErrorMessage('Respuesta inesperada del servidor.');
                }
            }
        } catch (error) {
            console.error(`Error occurred: ${error}`);  // Debug: Log any error
            setErrorMessage('Error al enviar la solicitud. Inténtelo de nuevo más tarde.');
        }
    };

    const handleCancel = () => {
        navigate('/login'); // Redirige a la página de inicio de sesión
    };

    return (
        <div className="forgot-password">
            <div className="background">
                <div className="city-background"></div>
                <div className="overlay"></div>
            </div>
            <div className="logos">
                <img className="logo-forgot-password" src={images['Logo.png']} alt="Logo" />
            </div>
            <div className="forgot-password-container">
                <div className="forgot-password-container-circle">
                    <div className="forgot-password-container-imgs">
                        <div className="forgot-password-icon2">
                            <img className="forgot-password-img2" src={images['grid-lock.png']} alt="Lock Icon" />
                        </div>
                    </div>
                </div>
                <div className="Reset-title">
                    Reset your password
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <p className="Reset-Subtext">
                    <span className="Reset-Subtext-text">
                        Enter your username so that an <span className="highlight">administrator</span> can receive your password reset request.
                    </span>
                </p>
                <div className="forgot-user-input">
                    <div className="forgot-user-input-icon">
                        <img className="forgot-user-input-img" src={images['people.png']} alt="User Icon" />
                    </div>
                    <input
                        type="text"
                        className="forgot-user-input-text"
                        placeholder="Username"
                        value={userName}
                        onChange={(e) => {
                            setUserName(e.target.value);
                            console.log(`Current userName: ${e.target.value}`);  // Debug: Check the userName input
                        }}
                    />
                </div>
                <div className="forgot-button-send" onClick={handleSendRequest}>
                    <span className="forgot-button-send-text">
                        Send request
                    </span>
                </div>
                <div className="forgot-button-cancel" onClick={handleCancel}>
                    <span className="forgot-button-cancel-text">
                        Cancel
                    </span>
                </div>
                <p className="forgot-end-text">
                    <span className="forgot-end-text-plus">
                        If you do not receive a response, please check your spam folder or <span className="highlight">resend the request.</span>
                    </span>
                </p>
                
            </div>
        </div>
    );
};

export default ForgotPassword;
