import React, { useState } from 'react';
import './css/AdmiSettings.css'; // Asumimos que crearemos un archivo CSS separado


const Button = ({ children, onClick, variant = "default" }) => (
  <button className={`button ${variant}`} onClick={onClick}>
    {children}
  </button>
);

const Card = ({ children, className = "" }) => (
  <div className={`card ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="card-header">{children}</div>;
const CardContent = ({ children }) => <div className="card-content">{children}</div>;
const CardTitle = ({ children }) => <h2 className="card-title">{children}</h2>;
const CardDescription = ({ children }) => <p className="card-description">{children}</p>;

const Tabs = ({ children, defaultValue }) => {
  const [activeTab, setActiveTab] = useState(defaultValue);
  return (
    <div className="tabs">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { activeTab, setActiveTab })
      )}
    </div>
  );
};

const TabsList = ({ children }) => <div className="tabs-list">{children}</div>;
const TabsTrigger = ({ children, value, activeTab, setActiveTab }) => (
  <button
    className={`tab-trigger ${activeTab === value ? 'active' : ''}`}
    onClick={() => setActiveTab(value)}
  >
    {children}
  </button>
);
const TabsContent = ({ children, value, activeTab }) => (
  activeTab === value ? <div className="tab-content">{children}</div> : null
);

const Dialog = ({ children, open, onOpenChange }) => (
  open ? (
    <div className="dialog-overlay" onClick={() => onOpenChange(false)}>
      <div className="dialog-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  ) : null
);

const DialogTrigger = ({ children, onClick }) => (
  React.cloneElement(children, { onClick })
);

const DialogContent = ({ children }) => <div className="dialog-body">{children}</div>;
const DialogHeader = ({ children }) => <div className="dialog-header">{children}</div>;
const DialogFooter = ({ children }) => <div className="dialog-footer">{children}</div>;
const DialogTitle = ({ children }) => <h2 className="dialog-title">{children}</h2>;
const DialogDescription = ({ children }) => <p className="dialog-description">{children}</p>;

const Label = ({ children, htmlFor }) => (
  <label className="label" htmlFor={htmlFor}>{children}</label>
);

const Input = ({ id, value, onChange, className = "" }) => (
  <input
    id={id}
    value={value}
    onChange={onChange}
    className={`input ${className}`}
  />
);

const Checkbox = ({ id, checked, onCheckedChange }) => (
  <input
    type="checkbox"
    id={id}
    checked={checked}
    onChange={(e) => onCheckedChange(e.target.checked)}
    className="checkbox"
  />
);

const Select = ({ children, onValueChange, defaultValue }) => (
  <select
    onChange={(e) => onValueChange(e.target.value)}
    defaultValue={defaultValue}
    className="select"
  >
    {children}
  </select>
);

const SelectTrigger = ({ children }) => <div className="select-trigger">{children}</div>;
const SelectValue = ({ children }) => <span className="select-value">{children}</span>;
const SelectContent = ({ children }) => <div className="select-content">{children}</div>;
const SelectItem = ({ children, value }) => (
  <option value={value} className="select-item">{children}</option>
);

const AdminSettings = () => {
  const [isCreateRoleDialogOpen, setIsCreateRoleDialogOpen] = useState(false);
  const [isEditRoleDialogOpen, setIsEditRoleDialogOpen] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [permissions, setPermissions] = useState({
    uploadFiles: false,
    viewLogs: false,
    createUser: false,
    editUsers: false,
  });

  const handleCreateRole = (e) => {
    e.preventDefault();
    console.log("Nuevo rol creado:", { name: newRoleName, permissions });
    setIsCreateRoleDialogOpen(false);
    setNewRoleName("");
    setPermissions({
      uploadFiles: false,
      viewLogs: false,
      createUser: false,
      editUsers: false,
    });
  };

  const handleEditRole = (e) => {
    e.preventDefault();
    console.log("Rol editado:", { name: selectedRole, permissions });
    setIsEditRoleDialogOpen(false);
    setSelectedRole("");
  };

  const handlePermissionChange = (permission) => {
    setPermissions(prev => ({ ...prev, [permission]: !prev[permission] }));
  };

  return (
    <div className="container">
      <h1 className="main-title">Configuración de Administrador</h1>
      <Tabs defaultValue="users">
        <TabsList>
          <TabsTrigger value="users">
            <span className="icon users-icon"></span>
            Usuarios
          </TabsTrigger>
          <TabsTrigger value="slider">
            <span className="icon image-icon"></span>
            Slider
          </TabsTrigger>
          <TabsTrigger value="roles">
            <span className="icon user-cog-icon"></span>
            Roles
          </TabsTrigger>
          <TabsTrigger value="history">
            <span className="icon history-icon"></span>
            Historial
          </TabsTrigger>
        </TabsList>
        <TabsContent value="users">
          <Card>
            <CardHeader>
              <CardTitle>Gestión de Usuarios</CardTitle>
              <CardDescription>Crear y ver usuarios del sistema.</CardDescription>
            </CardHeader>
            <CardContent>
              <Button>Crear Usuario</Button>
              <Button variant="outline">Ver Usuarios</Button>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="slider">
          <Card>
            <CardHeader>
              <CardTitle>Gestión del Slider</CardTitle>
              <CardDescription>Administrar imágenes del slider.</CardDescription>
            </CardHeader>
            <CardContent>
              <Button variant="destructive">Eliminar Todas las Imágenes</Button>
              <Button>Agregar Imágenes</Button>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="roles">
          <Card>
            <CardHeader>
              <CardTitle>Gestión de Roles</CardTitle>
              <CardDescription>Crear y editar roles de usuario.</CardDescription>
            </CardHeader>
            <CardContent>
              <Dialog open={isCreateRoleDialogOpen} onOpenChange={setIsCreateRoleDialogOpen}>
                <DialogTrigger>
                  <Button>Crear Role</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Crear Nuevo Role</DialogTitle>
                    <DialogDescription>
                      Define el nombre y los permisos para el nuevo role.
                    </DialogDescription>
                  </DialogHeader>
                  <form onSubmit={handleCreateRole}>
                    <div className="form-grid">
                      <div className="form-group">
                        <Label htmlFor="name">Nombre</Label>
                        <Input
                          id="name"
                          value={newRoleName}
                          onChange={(e) => setNewRoleName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <Label>Permisos</Label>
                        <div className="checkbox-group">
                          <div className="checkbox-item">
                            <Checkbox
                              id="uploadFiles"
                              checked={permissions.uploadFiles}
                              onCheckedChange={() => handlePermissionChange("uploadFiles")}
                            />
                            <label htmlFor="uploadFiles">Subir archivos</label>
                          </div>
                          <div className="checkbox-item">
                            <Checkbox
                              id="viewLogs"
                              checked={permissions.viewLogs}
                              onCheckedChange={() => handlePermissionChange("viewLogs")}
                            />
                            <label htmlFor="viewLogs">Ver logs</label>
                          </div>
                          <div className="checkbox-item">
                            <Checkbox
                              id="createUser"
                              checked={permissions.createUser}
                              onCheckedChange={() => handlePermissionChange("createUser")}
                            />
                            <label htmlFor="createUser">Crear usuario</label>
                          </div>
                          <div className="checkbox-item">
                            <Checkbox
                              id="editUsers"
                              checked={permissions.editUsers}
                              onCheckedChange={() => handlePermissionChange("editUsers")}
                            />
                            <label htmlFor="editUsers">Editar usuarios</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DialogFooter>
                      <Button type="submit">Crear Role</Button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
              <Dialog open={isEditRoleDialogOpen} onOpenChange={setIsEditRoleDialogOpen}>
                <DialogTrigger>
                  <Button variant="outline">Editar Role</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Editar Role</DialogTitle>
                    <DialogDescription>
                      Modifica los permisos del role seleccionado.
                    </DialogDescription>
                  </DialogHeader>
                  <form onSubmit={handleEditRole}>
                    <div className="form-grid">
                      <div className="form-group">
                        <Label htmlFor="role">Role</Label>
                        <Select onValueChange={setSelectedRole} defaultValue={selectedRole}>
                          <SelectTrigger>
                            <SelectValue placeholder="Selecciona un role" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="admin">Administrador</SelectItem>
                            <SelectItem value="editor">Editor</SelectItem>
                            <SelectItem value="viewer">Visualizador</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="form-group">
                        <Label>Permisos</Label>
                        <div className="checkbox-group">
                          <div className="checkbox-item">
                            <Checkbox
                              id="uploadFiles"
                              checked={permissions.uploadFiles}
                              onCheckedChange={() => handlePermissionChange("uploadFiles")}
                            />
                            <label htmlFor="uploadFiles">Subir archivos</label>
                          </div>
                          <div className="checkbox-item">
                            <Checkbox
                              id="viewLogs"
                              checked={permissions.viewLogs}
                              onCheckedChange={() => handlePermissionChange("viewLogs")}
                            />
                            <label htmlFor="viewLogs">Ver logs</label>
                          </div>
                          <div className="checkbox-item">
                            <Checkbox
                              id="createUser"
                              checked={permissions.createUser}
                              onCheckedChange={() => handlePermissionChange("createUser")}
                            />
                            <label htmlFor="createUser">Crear usuario</label>
                          </div>
                          <div className="checkbox-item">
                            <Checkbox
                              id="editUsers"
                              checked={permissions.editUsers}
                              onCheckedChange={() => handlePermissionChange("editUsers")}
                            />
                            <label htmlFor="editUsers">Editar usuarios</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DialogFooter>
                      <Button type="submit">Guardar Cambios</Button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="history">
          <Card>
            <CardHeader>
              <CardTitle>Historial</CardTitle>
              <CardDescription>Ver registros de actividad del sistema.</CardDescription>
            </CardHeader>
            <CardContent>
              <Button>Historial de Cambios</Button>
              <Button variant="outline">Historial de Logueo</Button>
              <Button variant="outline">Historial de Descargas</Button>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AdminSettings;