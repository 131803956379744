import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Slider from "./slider";
import Icon_menu from "./icon_menu";
import './css/recents.css';
import './css/downloadfile.css'



const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
};

const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));


const DownloadFile = () => {

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'doc':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return images['docx.png'];
      case 'application/pdf':
        return images['pdf.png'];
      case 'image/png':
        return images['png.png'];
      case 'jpg':
      case 'jpeg':
        return images['jpg.png'];
      case 'mp4':
        return images['mp4.png'];
      case 'application/x-msdos-program':
        return images['exe.png'];
      case 'image/jpeg':
        return images['jpg.png'];
      case 'image/gif':
        return images['gif.png'];
      case 'video/mp4':
        return images['mp4.png'];
      case 'pptx':
        return images['pptx.png'];
      case 'image/svg+xml':
        return images['svg.png'];
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return images['xsl.png'];
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return images['ppt.png'];
      case 'application/zip':
        return images['zip.png'];
      case 'application/vnd.rar':
        return images['rar.png'];
      case 'video/quicktime':
        return images['mov.png'];
      default:
        return images['document-upload.png'];
    }
  };


  const token = localStorage.getItem('token');
  const [Changes, setChanges] = useState([]);
  const [filteredChanges, setFilteredChanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("recent");
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [istrue, setIsTrue] = useState(false);
  const menuRef = useRef(null);
  const filesPerPage = 7;
  const [buttonName, setButtonName] = useState('Mas');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const otherMenu = () => {
    if (istrue === true){
      setIsTrue(false);
      setButtonName('Mas');
    }else{
      setIsTrue(true);
      setButtonName('Menos');
    }
  };

  const DownloadFs = async () => {
      if (istrue === true){
        try {
          const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://goldenowlsapi-testing.up.railway.app/api/ShowAllDownload`,
            headers: {}
          };
    
          const response = await axios(config);
          setChanges(response.data[0]);
          setFilteredChanges(response.data);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      }
      else{
        try{ 
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://goldenowlsapi-testing.up.railway.app/api/ShowDownloadLog`,
          headers: {}
        };
  
        const response = await axios(config);
        setChanges(response.data[0]);
        setFilteredChanges(response.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
      }
      
  };

  useEffect(() => {
    DownloadFs();
  }, [token]);
  


  useEffect(() => {
    if(istrue === true){
      let results = Changes.filter(change =>
        change.file_name.toLowerCase().includes(filter.toLowerCase()) ||
        change.Size.toLowerCase().includes(filter.toLowerCase()) ||
        change.Download.toLowerCase().includes(filter.toLowerCase()) 
      );
  
      if (sortOrder === "alphabeticalAsc") {
        results = results.sort((a, b) => a.file_name.localeCompare(b.file_name));
      } else if (sortOrder === "alphabeticalDesc") {
        results = results.sort((a, b) => b.file_name.localeCompare(a.file_name));
      } else if (sortOrder === "reverse") {
        results = results.reverse();
      }
  
      setFilteredChanges(results);
      setCurrentPage(1); // reset to first page when filter changes
    } else {
      let results = Changes.filter(change =>
        change.file_name.toLowerCase().includes(filter.toLowerCase()) ||
        change.Size.toLowerCase().includes(filter.toLowerCase()) ||
        change.Download.toLowerCase().includes(filter.toLowerCase()) 
      );
  
      if (sortOrder === "alphabeticalAsc") {
        results = results.sort((a, b) => a.file_name.localeCompare(b.file_name));
      } else if (sortOrder === "alphabeticalDesc") {
        results = results.sort((a, b) => b.file_name.localeCompare(a.file_name));
      } else if (sortOrder === "reverse") {
        results = results.reverse();
      }
  
      setFilteredChanges(results);
      setCurrentPage(1); // reset to first page when filter changes
    }
    
  }, [filter, Changes, sortOrder]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisibility(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (event, fileId) => {
    const buttonRect = event.target.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.top + window.scrollY + buttonRect.height,
      left: buttonRect.left + window.scrollX
    });
    setMenuVisibility((prev) => !prev);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const totalPages = Math.ceil(filteredChanges.length / filesPerPage);
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = filteredChanges.slice(indexOfFirstFile, indexOfLastFile);

  

  const handleSortOptionClick = (order) => {
    setSortOrder(order);
    setShowSortOptions(false);
  };

  const handleManageAccess = () => {
    console.log("Manage access clicked");
  };

  return (
    <div className="recents">
        
      <div className="search-bar">
          <input
            img src={images['search-normal.png']} alt=""
            type="text"
            placeholder="Search..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      <h1>Downloads</h1>
      <div className="activity-container">
        <h1>Download History</h1>
        
        <div className="sort-by">
          <div className="sort-by-img"><img src={images['filter.png']} alt="" />Filter</div>
          <button onClick={() => setShowSortOptions(!showSortOptions)}>Sort By {sortOrder}</button>
          {/*<button onClick={otherMenu}>{buttonName}</button>*/}
          {showSortOptions && (
            <div className="sort-options">
              <div onClick={() => handleSortOptionClick("recent")}>Recientes</div>
              <div onClick={() => handleSortOptionClick("reverse")}>Oldest</div>
              <div onClick={() => handleSortOptionClick("alphabeticalAsc")}>Alfabéticamente Ascendente</div>
              <div onClick={() => handleSortOptionClick("alphabeticalDesc")}>Alfabéticamente Descendente</div>
            </div>
          )}
        </div>
        
        <div className="back-black">
          <div className="cotent-table">
          {istrue ? (
          <table className="activity-table-df">
          <thead>
            <tr>
              <th className="th1">NAME</th>
              <th>USER</th>
              <th>SIZE</th>
              <th className="th2">DATE</th>
            </tr>
          </thead>
          <tbody >
            {currentFiles.map((activity, index) => (
              <tr key={index}>
                <td><img src={getFileIcon(activity.type)} alt={activity.type} className="file-icon" />
                    {activity.file_name}</td>
                <td>{activity.user_name}</td>
                <td>{activity.Size}</td>
                <td>{activity.Date}</td>
              </tr>
            ))}
          </tbody>
        </table>
          ):(
            <table className="activity-table-df">
              <thead>
                <tr>
                  <th className="th1">NAME</th>
                  <th>SIZE</th>
                  <th className="th2">Downloads</th>
                </tr>
              </thead>
              <tbody >
                {currentFiles.map((activity, index) => (
                  <tr key={index}>
                    <td><img src={getFileIcon(activity.type)} alt={activity.type} className="file-icon" />
                            {activity.file_name}</td>
                    <td>{activity.Size}</td>
                    <td>{activity.Download}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
            
          </div>
          <div className="nav-move-pages2">
        <button className="next" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          <div className="next-text">Anterior</div>
        </button>
        <div className="page-numbers">
          {Array.from({ length: totalPages }, (_, index) => {
            const pageNumber = index + 1;
            if (
              pageNumber === 1 ||
              pageNumber === totalPages ||
              pageNumber === currentPage ||
              pageNumber === currentPage - 1 ||
              pageNumber === currentPage + 1
            ) {
              return (
                <span
                  key={index}
                  className={`page-number ${currentPage === pageNumber ? 'active' : ''}`}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </span>
              );
            } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
              return <span key={index}>...</span>;
            }
            return null;
          })}
        </div>
        <button className="next" onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastFile >= filteredChanges.length}>
          <div className="next-text">Siguiente</div>
        </button>
      </div>
        </div>
        
      </div>
      <Slider />
      <Icon_menu />
      
    </div>
  );
};

export default DownloadFile;
