import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie'
import DeleteFolderModal from './DeleteFolderModal';
import EditIcon from '@mui/icons-material/Edit';
import EditFolderModal from './EditFolderModal';

// eslint-disable-next-line react-refresh/only-export-components
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}

    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'white', // font color
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      backgroundColor: 'rgb(24,24,24)', // Menu backgroundColor
      overflow: 'auto',   // Enable vertical scrolling if content exceeds max height
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18, // font size
        color: 'rgb(249, 180, 30)', // icon's color
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: 'white', // color when you click it
      },
      '&:hover': {
        backgroundColor: 'rgb(12,12,12)', // Menu' color when selected
      },
    },
    ...theme.applyStyles('dark', {
      color: 'red', // additional styling if needed
    }),
  },
}));

export default function FolderList({ folderId, folderName }) {
  const userII = JSON.parse(Cookies.get('userInfo'))
  const token = userII.user_id;  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openM, setOpenM] = React.useState(false)
  const [openMF, setOpenMF] = React.useState(false)

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenM(false);
    setOpenMF(false)
  };

  const openModalDelete = async () => {
    setOpenM(true)
  }

  const openEditModal = async () => {
    setOpenMF(true)
  }

  const downloadFolderAsZip = async (folderId, userId, folderName) => {
    console.log(folderId)
    try {
        handleClose();
        const response = await axios.post('https://api.goldenowlsagency.com/download-folder',
            { fileId:folderId, userId },
           { responseType: 'blob'}, // Necessary for downloading binary data (zip file)
        );

        // Create a URL for the zip file and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${folderName}.zip`); // Set the downloaded zip file name
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error('Error downloading the folder:', error);
    }
};



  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          backgroundColor: 'rgb(249, 180, 30)',
          '&:hover': {
            backgroundColor: 'black',
          },
        }}
      >
        More
      </Button>
      <StyledMenu
        id="demo-customized-menu"
    
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
          style: {
            maxHeight: '200px', // Set maximum height for scroll
            overflowY: 'auto',  // Enable scrolling if height exceeds
          },
        }
      }
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem  onClick={openEditModal}>
        <EditIcon />
        Edit
        </MenuItem>
        <MenuItem onClick={() => {downloadFolderAsZip(folderId, token, folderName); }} disableRipple>
          <DownloadIcon />
          Download
        </MenuItem>
        <MenuItem onClick={openModalDelete} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>
      </StyledMenu>

      <DeleteFolderModal openMenu={openM} closeMenu={handleClose} folderId={folderId}/>
      <EditFolderModal openMenu={openMF} closeMenu={handleClose} folderId={folderId}/>
        
    </div>
  );
}
