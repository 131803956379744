import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cookies from 'js-cookie';
import Edit_User_Modal from './user-edit-modal';
import DeleteUserModal from './DeleteUserModal';

// eslint-disable-next-line react-refresh/only-export-components
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}

    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'white', // font color
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      backgroundColor: 'rgb(24,24,24)', // Menu backgroundColor
      overflow: 'auto',   // Enable vertical scrolling if content exceeds max height
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18, // font size
        color: 'rgb(249, 180, 30)', // icon's color
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: 'white', // color when you click it
      },
      '&:hover': {
        backgroundColor: 'rgb(12,12,12)', // Menu' color when selected
      },
    },
    ...theme.applyStyles('dark', {
      color: 'red', // additional styling if needed
    }),
  },
}));

export default function User_Options({ UserId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          backgroundColor: 'rgb(249, 180, 30)',
          '&:hover': {
            backgroundColor: 'black',
          },
        }}
      >
        More
      </Button>
      <StyledMenu
        id="demo-customized-menu"
    
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
          style: {
            maxHeight: '200px', // Set maximum height for scroll
            overflowY: 'auto',  // Enable scrolling if height exceeds
          },
        }
      }
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        
          
          { UserId == 10 ? '':(
            <>
            <MenuItem disableRipple>
            <EditIcon />
            <Edit_User_Modal closeMenu={handleClose} User_Id={UserId} />
            </MenuItem>
            </>)}
        
        
        {UserId === 10 ? '' : (
            <>
            <MenuItem  disableRipple>
              <FileCopyIcon />
              <DeleteUserModal closeMenu={handleClose} userId={UserId} />
              </MenuItem>
            </>
          )}
       
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Logs
        </MenuItem>
      </StyledMenu>
    </div>
  );
}


