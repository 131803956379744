import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios';
import './css/Email.css';
import Slider from './slider';
import Icon_menu from './icon_menu';
import Cookies from 'js-cookie';
import { Mosaic } from 'react-loading-indicators';
import PostalMime from 'postal-mime';
import parse from 'html-react-parser'; // Importa html-react-parser
import { parseEml, readEml, GBKUTF8, decode } from 'eml-parse-js';

const EmailListItem = ({ email, isSelected, onSelect }) => (
  <div
    className={`emailItem ${isSelected ? 'selectedEmail' : ''}`}
    onClick={() => onSelect(email)}
  >
    <div className="sender">{email.parsedData.from.name}</div>
    <div className="subject">{email.parsedData.subject}</div>
    <div className="preview">...</div>
    {email.attachments && email.attachments.length > 0 && (
      <div className="attachmentIndicator">📎</div>
    )}
  </div>
);

const EmailDetail = ({ email, onDelete, isPhone, onClose }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    // Inspecciona el contenido antes de escribir en el iframe
    console.log('Contenido HTML:', email.parsedData.html);

    // Verifica si el HTML es una cadena válida antes de usarlo
    if (iframeRef.current && typeof email.parsedData.html === 'string') {
      const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(email.parsedData.html);
      doc.close();
    } else {
      console.error('El contenido HTML no es válido:', email.parsedData.html);
    }
  }, [email.parsedData.html]);

  return (
    <div className="emailDetail">
      {isPhone && <button onClick={onClose} className="closeButton">X</button>}
      <h2>{email.parsedData.subject}</h2>
      <p><strong>De:</strong> {email.parsedData.from.name}</p>
      <p><strong>Para:</strong> {email.parsedData.to.email}</p>
      <div className="emailBody">
        <iframe
          ref={iframeRef}
          sandbox="allow-same-origin allow-scripts"
          style={{ width: '100%', height: '500px', border: 'none' }}
          title="emailContent"
        ></iframe>
      </div>
      {email.attachments && email.attachments.length > 0 && (
        <div className="attachments">
          <h3>Archivos adjuntos:</h3>
          <ul>
            {email.attachments.map((file, index) => (
              <li key={index}>{file.name} ({Math.round(file.size / 1024)} KB)</li>
            ))}
          </ul>
        </div>
      )}
      <button onClick={() => onDelete(email.id)} className="deleteButton">
        Borrar correo
      </button>
    </div>
  );
};
export default function Email() {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isPhone, setIsPhone] = useState(false);
  const [loading, setLoading] = useState(true);
  const userII = JSON.parse(Cookies.get('userInfo'));
  const token = userII.user_id;


  // Función para obtener los correos
  const fetchEmails = async () => {
    try {
      const response = await axios.post('https://api.goldenowlsagency.com/get-email', { userId: token });
      
      // Crear una promesa para procesar todos los correos usando readEml
      const processedEmails = await Promise.all(
        response.data.map((email) =>
          new Promise((resolve, reject) => {
            // Procesar cada correo con readEml
            readEml(email.raw, (err, ReadEmlJson) => {
              if (err) {
                reject(err);
              } else {
                resolve({ ...email, parsedData: ReadEmlJson });
              }
            });
          })
        )
      );
  
      // Guardar los correos procesados en el estado
      setEmails(processedEmails);
  
      // Imprimir los correos procesados en la consola
      //console.log("Correos procesados:", processedEmails);
  
    } catch (error) {
      console.error('Error al obtener correos:', error);
    }
  };
  

  // Cargar los correos al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      await fetchEmails();
      setLoading(false);
    };
    fetchData();
  }, []);

  // Detectar si el dispositivo es un teléfono
  const detectPhone = () => {
    setIsPhone(window.innerWidth < 768);
  };

  useEffect(() => {
    detectPhone();
    window.addEventListener('resize', detectPhone);
    return () => window.removeEventListener('resize', detectPhone);
  }, []);

  // Cerrar la vista del detalle del correo
  const closeEmailDetail = () => {
    setSelectedEmail(null);
  };

  // Si el usuario no está logueado
  if (userII.if_login === 0) {
    return (
      <div className='email-no-login'>
        <div className='content-email-not'>
          <h1>Content not available</h1>
          <p>Please login with google to see this content</p>
          <a href='/UserInfo'>Click Here 👈</a>
        </div>
        <Slider />
        <Icon_menu />
      </div>
    );
  } else if (loading) {
    return (
      <div className='loading'>
        <Mosaic color="rgb(249,180,30)" size="large" text="Loading..." textColor="" />
      </div>
    );
  }

  return (
    <div className="app">
      <div className="header">
        <h1>Email</h1>
      </div>
      <div className="main">
        <div className="sidebarE">
          {emails.map(email => (
            <EmailListItem
              key={email.id}
              email={email}
              isSelected={selectedEmail && selectedEmail.id === email.id}
              onSelect={setSelectedEmail}
            />
          ))}
        </div>
        <div className="content">
          {selectedEmail ? (
            <EmailDetail
              email={selectedEmail}
              onDelete={(id) => setEmails(emails.filter(email => email.id !== id))}
              isPhone={isPhone}
              onClose={closeEmailDetail}
            />
          ) : (
            <p>Selecciona un correo para ver detalles</p>
          )}
        </div>
      </div>
      <Slider />
      <Icon_menu />
    </div>
  );
}
