import React, { useState, useEffect } from 'react';
import './css/login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'


const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

// eslint-disable-next-line no-undef
const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const texts = {
  es: {
    emailLabel: 'Ingrese su Usuario',
    passwordLabel: 'Contraseña',
    rememberMeLabel: 'Recuérdame',
    forgotPasswordText: '¿Olvidaste tu contraseña?',
    loginButtonText: 'Iniciar sesión',
    welcomeBackText: '¡Bienvenido de nuevo, te hemos extrañado!',
    invalidCredentialsTitle: 'Credenciales incorrectas',
    invalidCredentialsDescription: 'Las credenciales ingresadas no son válidas. Por favor, asegúrate de ingresar el usuario y la contraseña correctos.',
    closeModalText: 'Cerrar',
    emailPlaceholder: 'Ingresa su usuario',
    passwordPlaceholder: 'Ingresa tu contraseña',
    adminText: 'Administrador',
    userText: 'Usuario'
  },
  en: {
    emailLabel: 'Your email',
    passwordLabel: 'Password',
    rememberMeLabel: 'Remember Me',
    forgotPasswordText: 'Forgot your password?',
    loginButtonText: 'Log In',
    welcomeBackText: 'Welcome back, we missed you!',
    invalidCredentialsTitle: 'Invalid Credentials',
    invalidCredentialsDescription: 'The credentials you entered are not valid. Please make sure you enter the correct email and password.',
    closeModalText: 'Close',
    emailPlaceholder: 'Enter your Username',
    passwordPlaceholder: 'Enter your password',
    adminText: 'Administrator',
    userText: 'User'
  }
};

const Login = () => {
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState('es');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const currentTexts = texts[currentLanguage];

  useEffect(() => {
    const isAdminStored = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(isAdminStored);
  }, []);

  const handleLogin = async () => {
    const url = `https://api.goldenowlsagency.com/login/${user}/${pass}`;

    const data = JSON.stringify({
      "userName": user,
      "userPassword": pass
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.goldenowlsagency.com/LoginV2',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    try {
      const response = await axios.request(config);

      await Cookies.set('userInfo', JSON.stringify(response.data.data))

      navigate('/WorkSpace');
    }
    catch (error) {
      setErrorMessage(currentTexts.invalidCredentialsDescription);
      setShowModal(true);
    }

   
  }

  const togglePassword = () => {
    setShowPassword(!showPassword);
  }

  const closeModal = () => {
    setShowModal(false);
    setErrorMessage('');
  }

  const handleLanguageChange = (e) => {
    setCurrentLanguage(e.target.value);
  }

  return (
    <div className="log-in">
      <div className="background">
        <div className="city-background"></div>
      </div>
      <div className="login-container">
        <div className="logo">
          <img src={images['Logo.png']} alt="Golden Owl Logo" />
        </div>
        <div className="login-form">
          <h2>{currentTexts.loginButtonText}</h2>
          <p>{currentTexts.welcomeBackText} 👋</p>
          <div className="input-group">
            <label htmlFor="email">{currentTexts.emailLabel}</label>
            <input type="text" id="email" name="email"
              placeholder={currentTexts.emailPlaceholder}
              value={user}
              onChange={(e) => setUser(e.target.value)} />
          </div>
          <div className="input-group password-grouplogin">
            <label htmlFor="password">{currentTexts.passwordLabel}</label>
            <div className="password-input-wrapper_login">
              <input type={showPassword ? "text" : "password"} id="password" name="password"
                placeholder={currentTexts.passwordPlaceholder}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin();
                  }
                }}
              />
              <button type="button" className="password-toggle-login" onClick={togglePassword}>
                {showPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
              </button>
            </div>
          </div>
          <div className="options">
            <div className="remember-me">
              <input type="checkbox" id="remember-me" />
              <label htmlFor="remember-me">{currentTexts.rememberMeLabel}</label>
            </div>
            <Link to="/forgot-password">{currentTexts.forgotPasswordText}</Link>
          </div>
          <button className="login-button"
            onClick={handleLogin}
          >{currentTexts.loginButtonText}</button>
          <p className="signup">{currentTexts.signupText} <a href="/signup">{currentTexts.signupLink}</a></p>
        </div>
        {errorMessage && <p className="error-message_Login">{errorMessage}</p>}
      </div>
    </div>
  );
}

export default Login;
