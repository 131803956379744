import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './css/icon_menu.css'
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { jwtDecode } from "jwt-decode";
import getGoogleUserProfile from '../func/test';
import Cookies from 'js-cookie'


const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// eslint-disable-next-line no-undef
const images = importAll(require.context('./image', false, /\.(png|jpe?g|svg)$/));

const Icon_menu = () => {
  const userInfo = JSON.parse(Cookies.get('userInfo'))
  const [phone, setPhone] = useState(true)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
})

  const token = userInfo.user_id;
  const [imgUser2, SetImgUser] = useState('');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    navigate('/');
  };

  useEffect(() => {
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
       if(windowSize.width >= 768){
        setPhone(false)
       }
    };
}, []);


const getImgUrl = () => {
  try{
      if (userInfo.user_img !== null){
          return userInfo.user_img;
      } else {
          return images['usuario.png']
      }
      
  } catch {
      return 'There is not a img'
  }
  
};


const fetchUserImage = async () => {
  
  const user_img = await getImgUrl();

  try {
      SetImgUser(user_img);
 

  } catch (error) {
    console.error('Error al obtener la imagen del usuario:', error);
    SetImgUser(images['usuario.png'])
  }
};


  useEffect(() => {
    fetchUserImage(); // Call the function with the desired userId
  }, []);
  
  return (
    <React.Fragment>
      <Box sx={{ display: 'contents', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'center', width: '100%' }}>
        <Tooltip title="Account settings">
          {phone ? (
            <IconButton
            onClick={handleClick}
            size="small"
            sx={{ position: 'absolute', right: '3vh', top:' 2.5vh' }}  // Moves the IconButton to the right
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 45, height: 45 }}>
              <img src={imgUser2} alt="" style={{ width: '100%', height: '100%' }}/>
            </Avatar>
          </IconButton>
          ):(<IconButton
            onClick={handleClick}
            size="small"
            sx={{ position: 'absolute', right: '5vh', top:' 3vh' }}  // Moves the IconButton to the right
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 45, height: 45 }}>
              <img src={imgUser2} alt="" style={{ width: '100%', height: '100%' }}/>
            </Avatar>
          </IconButton>)}
          
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            fontFamily:'Manrope',
            color: 'white',
            backgroundColor: 'rgb(12, 12, 12)',  // Change background color to black
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.white',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      ><a href="/UserInfo" style={{ textDecoration: 'none', color: 'white' }}>
        <MenuItem onClick={handleClose} sx={{ ':hover':{ backgroundColor: 'rgb(232, 167, 41)'} }} >
          <Avatar sx={{ bgcolor: 'white', color: 'black', }} /> 
          Profile
        </MenuItem></a> 
     
        <Divider sx={{ bgcolor: 'grey' }} />
        
        { userInfo.role_name !== 'Usuario Normal' &&( 
        <a href='/Settings' style={{textDecoration:'none', color:'white'}}>
        <MenuItem onClick={handleClose} sx={{ ':hover':{ backgroundColor: 'rgb(232, 167, 41)'} }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        </a>
        )}
        <MenuItem onClick={logout} sx={{ ':hover':{ backgroundColor: 'rgb(232, 167, 41)'} }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default Icon_menu;